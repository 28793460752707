@import "variables.scss";
/** app.scss */

html {
  overflow-y: scroll;
}

::-webkit-scrollbar-button {
  display: none;
  height: 13px;
  border-radius: 0px;
  background-color: #ccc;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

::-webkit-scrollbar-track {
  background-color: #efefef;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

.navbar-bread-crumbs {
  display: flex !important;

  a {
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
  }

  @media screen and (max-width: 1100px) {
    & {
      display: block !important;
      font-size: 10px;
      text-align: left !important;

      a {
        display: block;
      }
    }
  }
}

.sceneries-container-edit-mode {
  .cenary-themes-container {
    background-color: #f1f1f1;
  }
}

.select-insight {
  cursor: pointer;

  &:hover {
    color: #000;
    font-weight: 500;
  }
}

.changelog-dialog {
  max-height: 70vh;
  overflow-x: auto;
  border-bottom: 1px solid #ccc;
}

%panel-button-shared {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  transition: 0.2s;

  &:hover {
    color: #fff;
    cursor: pointer;
  }
}

.workflow-container {
  position: relative;
  min-height: 250px;

  .workflow-top-actions {
    width: 250px;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 15px;
    display: flex;
    align-items: flex-end;
    z-index: 20;
    flex-direction: column;

    button {
      border: 1px solid #ccc !important;
      width: auto;
      min-width: 215px;
      text-align: center;
      margin-bottom: 10px;
      font-size: 12px;

      &:hover {
        background-color: #ddd;
      }
    }

    .next-button {
      border: 1px solid #111;
      background-color: #e1e1e1;
      font-weight: bold;

      &:hover {
        background-color: #ccc;
      }
    }
  }

  .action-arrow {
    font-size: 16px;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    user-select: none;

    .arrow {
      color: #666;
      font-size: 14px;
      position: relative;
      top: -1px;
    }
  }

  .action-arrow-left {
    border-top-right-radius: 0px;
  }

  .action-arrow-right {
    border-top-left-radius: 0px;
  }
}

.workflow-header {
  .member-avatar {
    width: 70px;
    height: 70px;
    line-height: 70px;
    margin-right: 40px;
    background-color: #ddd;
    text-align: center;
    border-radius: 50%;
    display: block;
    float: left !important;
    font-size: 34px;
  }

  .member-name {
    margin-top: 0px;
    position: relative;
    left: -20px;
  }

  .member-subtitles {
    position: relative;
    left: -18px;
    font-weight: bold;

    .title {
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
    }
  }
}

.workflow-table {
  thead td {
    text-align: center;
  }

  tbody td {
    padding: 0px 7px !important;
  }

  .input-td {
    padding: 0px !important;
    height: 40px;
  }
}

.flex-input-control {
  display: flex !important;
  height: 100%;

  & > div {
    flex: 1 1 auto !important;
  }
}

.chart-filter {
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }
}

.chart-selected {
  background-color: #f0f0f0;
  &:hover {
    background-color: #f0f0f0;
  }
}

.evaluation-line {
  display: flex;
  align-items: center;

  .number {
    min-width: 50px;
    padding: 0px 5px 0px 0px;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    text-align: right;
  }

  .percentage {
    display: block;
    width: 100%;
    overflow: hidden;
  }
}

.chart-col {
  width: 20%;
  border-radius: 6px;

  .chart {
    position: relative;

    .total-count {
      position: absolute;
      text-align: center;
      top: 40%;
      margin: 0 auto;
      left: 0;
      right: 0;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .bottom-table {
    width: 70%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 0px 15px;
    font-size: 11px;
    white-space: nowrap;

    td {
      padding: 10px;
    }
  }
}

.status-overview-charts {
  display: flex;
  align-items: center;
  padding: 15px 0px;

  .title-field {
    width: 20%;
  }

  .chart-field {
    width: 80%;
    display: flex;
    overflow-y: auto;
  }
}

.participant-details-modal {
  width: 900px !important;

  .simple-table {
    font-size: 10px;
  }
}

@media print {
  .status-overview .status-overview-charts {
    display: block !important;
    align-items: center;
    padding: 15px 0px;
    width: 100%;
    justify-content: space-between;

    .title-field {
      width: 100% !important;
      font-size: 8px !important;
      overflow-x: auto;
    }

    .chart-field {
      width: 100% !important;
      flex-flow: row wrap;
    }

    .chart-col {
      width: 33.33;
      margin: 0px 15px;
      h5,
      h6 {
        font-size: 8px;
        text-align: right !important;
        margin: 15px 0px 10px 0px;
      }
      .bottom-table {
        font-size: 8px;
        margin-bottom: 60px;
        td {
          text-align: right !important;
        }
      }
    }
  }
}

@media print {
  .status-overview .status-overview-charts {
    display: block !important;
    align-items: center;
    padding: 15px 0px;
    width: 100%;
    justify-content: space-between;

    .title-field {
      width: 100% !important;
      font-size: 8px !important;
      overflow-x: auto;
    }

    .chart-field {
      width: 100% !important;
      flex-flow: row wrap;
    }

    .chart-col {
      width: 33.33;
      margin: 0px 15px;
      h5,
      h6 {
        font-size: 8px;
        text-align: right !important;
        margin: 15px 0px 10px 0px;
      }
      .bottom-table {
        font-size: 8px;
        margin-bottom: 60px;
        td {
          text-align: right !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .status-overview-charts {
    display: block;

    .title-field {
      width: 100%;
      border-bottom: 2px solid #ccc;
      padding-bottom: 10px;
      margin-bottom: 15px;
    }

    .chart-field {
      width: 100%;

      .chart-col .chart .total-field {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .status-overview-charts {
    .chart-field {
      .chart-col {
        width: 33.3% !important;
      }
    }
  }
}

@mixin panelSimpleButton($property) {
  color: $property;

  &:hover {
    background-color: $property;
  }
}

.panel-simple-remove-button {
  @extend %panel-button-shared;
  @include panelSimpleButton(tomato);
}

.panel-simple-user-button {
  @extend %panel-button-shared;
  @include panelSimpleButton(#5b5e6c);

  &:hover {
    background-color: #ccc !important;
  }
}

.area-responsible {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-right: 3px;
  border-radius: 4px;
  transition: 0.2s;

  div {
    width: 25px;
    height: 25px;
  }

  span {
    margin-right: 5px;
    font-size: 13px;
    color: #5f6270;
    white-space: nowrap;
    transition: 0.2s;
  }

  &:hover span {
    color: #fff;
  }

  &:hover {
    background-color: #ccc;
  }
}

.flex-transition-container {
  display: flex;
  transition: 0.4s;
  overflow: hidden;
}

.name-tab-pdf {
  margin-left: 20px;
  display: none;
}

.tool-title-pdf {
  position: absolute;
  top: 26px;
  right: 40px;
  display: none;
}

.hover-menu-dots {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0);
  padding: 8px 13px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.activityOptions .hover-menu-dots {
  width: 100%;
  display: block;
  border-radius: 0px;
}

.actions-plan-evaluation {
  text-align: center;

  .evaluation-col {
    background-color: #eee;
    font-weight: 500;
    font-size: 26px;
  }
}

.material-editable-field {
  display: flex;
  align-items: flex-end;

  .prefix {
    position: relative;
    padding: 11px 0px 10px 0px;
  }
}

.tool-container .tool-header {
  padding-top: 40px;
  position: relative;
  width: 100%;
  background-color: #fff;

  .nav-tabs-custom {
    height: 50px;
    padding-left: 20px;
    background-color: #fff !important;
    opacity: 1 !important;
    display: flex;
    width: calc(100% - 246px);
    justify-content: space-between;
    position: fixed;
    top: 50px;
    z-index: 100;

    .tool-title {
      margin: 0px;
      line-height: 54px;
      white-space: nowrap;
    }

    ul li {
      height: 52px;

      a {
        display: block;
        height: 100%;
        line-height: 28px;
      }
    }

    @media screen and (max-width: 1440px) {
      .tool-title {
        font-size: 16px;
      }

      ul li a {
        font-size: 12px;
        padding: 10px;
      }
    }

    @media screen and (max-width: 1240px) {
      ul li a {
        font-size: 10px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    @media screen and (max-width: 1100px) {
      .tool-title {
        font-size: 12px !important;
      }
    }
  }

  .nav-tabs-custom-collapsed {
    width: calc(100% - 65px) !important;
  }
}

.scenerie-button-add {
  background-color: #6b42a9;
  color: #fff;
  width: 25px;
  height: 50px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;

  svg {
    font-size: 20px;
  }

  &:hover {
    background-color: #603a99;
  }
}

.scenerie-button-edit-mode,
.button-cancel-edit-mode {
  background-color: #eee;
  height: 50px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  line-height: 50px;
  padding: 0px 5px;
  margin-bottom: 5px;
  color: #333;
  border: 1px solid #ccc;
  font-weight: bold;
  white-space: nowrap;
  border-radius: 6px;
}

.scenerie-button-edit-mode {
  background-color: #00e075;

  &:hover {
    background-color: #00b860;
  }
}

.button-cancel-edit-mode {
  &:hover {
    background-color: #fa4b4b;
    color: #fff;
  }
}

.evaluation-row {
  display: flex;
  align-items: center;
}

.insight-box {
  width: 100%;
  padding: 5px 10px 5px 20px;
  border: 1px solid #eee;
  display: flex;
  height: 40px;
  line-height: 40px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  .clickable-row {
    cursor: pointer;
    flex: auto;
    overflow: hidden;
    max-width: 1100px;

    .editable-field {
      div {
        width: 100%;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
  }

  @media screen and (max-width: 1420px) {
    .clickable-row {
      max-width: 800px;
    }
  }

  &:hover {
    background-color: #f6f6f6;
  }
}

.add-insight-button {
  width: 100%;
  padding: 0px 15px;
  border: 1px solid #eee;
  border-bottom: 2px solid #eee;
  height: 35px;
  color: #6b42a9;
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  margin: 0px !important;

  &:hover {
    background-color: #f6f6f6;
  }

  svg {
    position: relative;
    top: 2px;
  }
}

#targetConfitTable .material-single-select fieldset {
  border: none;
}

.simple-relative-controller {
  position: absolute;
  width: 100%;
  bottom: -20px;
  font-size: 15px;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  z-index: 100;

  i {
    padding: 5px;
    color: #fff;
  }

  .button {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transform: scale(1.2);
    }
  }

  .save {
    background-color: #008143;
  }

  .cancel {
    background-color: #ff6347;
  }
}

.error-message {
  display: inline-block;
}

.goal-period-tooltip {
  background-color: #fff;
  font-size: 11px !important;
  text-align: right !important;
  padding: 5px !important;

  .label {
    color: #111 !important;
    font-size: 14px !important;
    padding: 0px !important;
    margin-bottom: 8px !important;
    display: block;
    text-align: right !important;
  }
}

.new-goal-dialog {
  width: 1100px;
}

.goal-list-item {
  display: flex;
  min-height: 50px;
  border: 1px solid #ddd;
  align-items: center;
  border-radius: 4px;
  margin: 10px 0px;
  justify-content: space-between;
  color: #333;

  .title {
    width: auto;
    padding: 0px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 14px;
  }

  .title-goal-item {
    padding: 0px 10px;
    white-space: pre-line;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 14px;
  }

  .description {
    width: auto;
    padding: 0px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #666;
  }

  .description-goal-item {
    padding: 0px 10px;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #666;
  }

  .percentage {
    padding: 0px 10px;
    width: 75px;
  }

  .bar {
    margin: 0px 10px 0px 0px;
    height: 20px;
    background-color: #f6f6f6 !important;

    .progress {
      height: 20px;
      margin: 0 auto;
    }
  }
}

.margin-auto {
  margin: 0 auto !important;
}

.activities-table-actions {
  button {
    background-color: #eee;

    &:hover {
      border: 1px solid #ccc;
      background-color: #f1f1f1;
    }
  }

  .active {
    background-color: #fff !important;
    box-shadow: none !important;
    border: 1px solid #ccc;
    cursor: default;
  }
}

.max-height-800 {
  max-height: 800px;
  overflow-x: auto;

  .right-spacing {
    padding-right: 15px;
  }
}

.nav-tabs-custom .nav-tabs .no-border {
  border: none !important;
}

.gray-hover {
  transition: 0.3s;

  &:hover {
    background-color: #eee;
  }
}

.nav-tabs-custom {
  background-color: transparent !important;

  ul.nav-tabs {
    margin-bottom: 0px !important;
    border-bottom: 1px solid #eee;
    background-color: #fff;
  }

  li {
    margin: 0 !important;
    background-color: transparent !important;
    border: 1px solid #eee !important;
    outline: none;

    a {
      border: none !important;
      margin: 0 !important;
    }

    &.active {
      a {
        background-color: #f9f9f9 !important;
        font-weight: 500;
      }

      border-bottom: 3px solid #6b42a9 !important;
    }
  }
}

.select-tree-dialog {
  width: 800px;
}

.simple-selection-button {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
}

.breadcrumb-button {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 6px;

  &:hover {
    background-color: #eee !important;
  }
}

.row {
  margin: 0;
}

.slick-prev,
.slick-next {
  font-size: 15px !important;
}

.link-breadcrumb:hover {
  text-decoration: underline;
}

.slick-prev::before,
.slick-next::before {
  content: "" !important;
}

.col-pd-panel {
  padding: 10px 15px;
}

.buttons-bootstrap-table {
  margin-left: -15px;
  padding: 10px 0px 8px 0px;
}

.th-ordenation {
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}

.table-dropdown {
  .th-ordenation:hover {
    background-color: #573191;
  }

  thead tr th {
    border: 1px solid #ccc !important;
    background-color: #6b42a9;
    color: #fff;
  }

  .parent-row {
    cursor: pointer;
    user-select: none;
    transition: 0.4s;

    td {
      border: 1px solid #eee;
      border-bottom: 2px solid #eee;
      padding: 10px;
    }
  }

  .child-row {
    td {
      padding: 10px;
      background-color: #f3f3f3;
      border: 1px solid #ddd;
      border-bottom: 2px solid #ddd;
    }
  }
}

.form-error {
  color: #b66d6d;
}

.checkbox-wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #f9f9f9;
  }
}

.simple-hover {
  transition: 0.3s;

  &:hover {
    background-color: #eee !important;
  }
}

.list-item-selection {
  position: relative;
  left: 0;
  cursor: pointer;
  border-left: 8px solid transparent;
  transition: 0.2s ease-in-out;
  user-select: none;
  overflow: hidden;

  &:hover {
    border-top: 2px solid #ccc !important;
    border-bottom: 2px solid #ccc !important;
  }
}

.ascention-step-line {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  justify-content: space-between;
  position: relative;

  li {
    text-align: center;

    i {
      font-size: 24px;
      position: absolute;
      color: #999;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 6px;
    }

    .secondary {
      font-size: 18px;
    }
  }
}

.vertical-simple-table {
  width: 100%;

  tbody {
    tr:hover {
      background-color: #f1f1f1;
    }

    td {
      border: 1px solid #ddd;
      font-size: 15px;
    }
  }
}

.monthLabel {
  width: 50px !important;
  background-color: #efefef;
}

.monthValue {
  width: 120px;
  background-color: "#efefef";
}

.fixed-table-date {
  width: 190px;
}

.fixed-table-price {
  width: 190px;
}

.fixed-table-action {
  width: 75px;
}

.bg-white {
  background-color: #fff !important;
}

.home-banner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  background-color: #111;

  #mainVideo {
    width: 100%;
  }
}

fieldset legend {
  border: none !important;
}

.custom-primary-tabs-adm .nav-tabs {
  border: none !important;
  padding: 0px 15px;

  li {
    border: 1px solid #ddd !important;
    margin-right: 5px !important;
    border-radius: 4px !important;
    position: relative;

    a {
      margin: 0px;
    }

    .tab-title {
      display: flex;
      height: 20px;
      line-height: 20px;

      i {
        padding: 0px 10px 0px 0px;
        line-height: 20px;
      }

      .tab-error-count {
        width: 22px;
        height: 22px;
        background-color: #ff6347;
        border-radius: 50%;
        position: absolute;
        top: -5px;
        right: -5px;
        text-align: center;
        font-size: 11px;
        font-weight: bold;
        line-height: 22px;
        color: #fff;
      }
    }

    &:hover {
      border: 1px solid #999 !important;

      a {
        background-color: #eee;
      }
    }

    &.active {
      border: 1px solid transparent !important;

      a {
        background-color: #6b42a9 !important;
        border-radius: 4px !important;
        color: #fff !important;
      }
    }
  }
}

.custom-primary-tabs .nav-tabs {
  border: none !important;
  padding: 0px !important;

  li {
    border: 1px solid #ddd !important;
    margin-right: 5px !important;
    border-radius: 4px !important;
    position: relative;

    a {
      margin: 0px;
    }

    .tab-title {
      display: flex;
      height: 20px;
      line-height: 20px;

      i {
        padding: 0px 10px 0px 0px;
        line-height: 20px;
      }

      .tab-error-count {
        width: 22px;
        height: 22px;
        background-color: #ff6347;
        border-radius: 50%;
        position: absolute;
        top: -5px;
        right: -5px;
        text-align: center;
        font-size: 11px;
        font-weight: bold;
        line-height: 22px;
        color: #fff;
      }
    }

    &:hover {
      border: 1px solid #999 !important;

      a {
        background-color: #eee;
      }
    }

    &.active {
      border: 1px solid transparent !important;

      a {
        background-color: #6b42a9 !important;
        border-radius: 4px !important;
        color: #fff !important;
      }
    }
  }
}

.card-value {
  position: relative;
  padding: 8px;
  background-color: #f1f1f1;
  border-radius: 6px;
  font-size: 26px;
  margin-bottom: 15px;
  transition: 0.4s;

  .label {
    position: absolute;
    font-size: 10px;
    top: -5px;
    left: -5px;
    padding: 5px;
    color: #333;
    border: 1px solid #ccc;
    background-color: #eee;
  }

  .label-bottom {
    top: auto;
    left: auto;
    bottom: -5px;
    right: -5px;
  }
}

.card-value {
  position: relative;
  padding: 8px;
  background-color: #f1f1f1;
  border-radius: 6px;
  font-size: 26px;
  margin-bottom: 15px;
  transition: 0.4s;

  .label {
    position: absolute;
    font-size: 10px;
    top: -5px;
    left: -5px;
    padding: 5px;
    color: #333;
    border: 1px solid #ccc;
    background-color: #eee;
  }

  .label-bottom {
    top: auto;
    left: auto;
    bottom: -5px;
    right: -5px;
  }
}

.evaluation-card {
  margin: 10px 0px 20px 0px;
  border: 1px solid #ddd;
  border-radius: 8px;
  border-top-left-radius: 0px;

  .card-header {
    display: flex;
    align-items: center;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background-color: #f1f1f1;
    margin: 0px;
    padding: 0px 10px 0px 10px;

    .card-title {
      font-size: 18px !important;
      text-align: center;
    }

    .card-badge {
      width: 100px;
    }
  }

  .card-header-hover {
    transition: 0.3s;

    &:hover {
      background-color: #dfdfdf;
    }
  }

  .card-body {
    padding: 5px 0px 20px 0px;
    border-top: none;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .padding-row {
      padding-top: 20px;
    }
  }
}

.swot-responsible-table {
  width: 80%;
  margin: 0 auto;

  @media screen and (max-width: 1700px) {
    width: 100%;
  }
}

.progress-striped {
  margin: 0;
}

.progress {
  height: 10px;
  margin: 0 !important;
}

.advanceSubHeader {
  margin: 0px !important;
  padding: 0px !important;
}

.reportMapPhyisical {
  width: 65px;
  height: 100% !important;
  border-radius: 0px !important;
  background-color: white !important;
  display: flex;
  align-items: center;
}

.advanceCenter {
  z-index: 999;
  color: black;
}

.progressProject {
  height: 10px !important;
  margin-bottom: 0px !important;
}

.bg-insight-modal {
  background-color: #6b42a9;
  color: #fff;
  padding: 0px 15px;
  position: relative;

  .modal-title {
    h3 {
      margin: 10px 0px 0px 0px;
    }
  }

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 15px;
  }

  button {
    background-color: transparent;
    color: #fff;
    opacity: 1;
  }
}

.flex-cards {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: 15px 0;
}

.flex-card {
  border-radius: 5px;

  .card-title {
    padding: 10px;
    margin: 0px;
  }

  .card-content {
    padding: 20px;
  }

  .card-drag-item {
    padding: 6px;
    margin: 20px 0;
    background-color: #eee;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  }
}

.card-container {
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-top: 5px;
  margin-bottom: 25px;

  .card-title {
    margin: 0px;
    padding: 20px;
  }

  .card-body {
    padding: 5px 20px;
    background-color: #f6f6f6;

    .card-row {
      padding: 10px 0;

      .row-title {
        color: #333;
        margin: 0px;
        padding-left: 14px;
        border-left: 5px solid #666;
        margin-left: -20px;
      }

      .row-table {
        width: 100%;
        border-bottom: 1px solid #ccc;

        .table-title {
          margin: 18px 0px 10px 0px;
        }

        table {
          width: 100%;
          margin: 15px 0px 25px 0px;
          background-color: #f1f1f1;

          thead {
            background-color: #eee;
          }

          thead th {
            padding: 15px;
          }

          thead th:hover {
            background-color: #e1e1e1;
          }

          tbody td {
            padding: 15px;
          }

          tbody td:hover {
            background-color: #e3e3e3;
          }
        }
      }
    }
  }
}

.table-evaluation {
  background-color: #f6f6f6;
  border-radius: 5px;
  color: #222;

  th {
    background-color: #f1f1f1;
  }

  tr:hover {
    background-color: #ddd;
  }

  td {
    transition: 0.3s;
  }

  thead th {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  thead th:first-child {
    padding-left: 10px;
  }

  thead th:last-child {
    padding-right: 10px;
  }

  tbody td:first-child:hover {
    background-color: transparent;
    cursor: default;
    color: #222;
  }

  tbody td:hover {
    background-color: #ccc;
    color: #222;
    cursor: pointer;
  }

  tfoot td {
    padding: 10px;
    background-color: #f1f1f1;
  }
}

.readOnly tbody td:hover {
  cursor: text !important;
}

.bg-purple {
  background-color: $purple;
}

.recharts-wrapper .recharts-legend-wrapper {
  bottom: -8px !important;

  .recharts-legend-item {
    border: none !important;
    padding: 5px;
    margin: 0 10px 0 0 !important;
  }
}

.recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick-value {
  font-size: 12px;
}

.nine-box-evaluation {
  width: 100%;
  margin: 0 auto;

  .cards {
    display: flex;
    margin: 0 auto;
    flex-flow: row wrap;

    .card {
      position: relative;
      flex: 0 0 calc(33.3% - 22px);
      text-align: center;
      padding: 5px;
      margin: 10px;
      color: #333;
      background-color: #eee;
      border: 1px solid #ccc;
      font-size: 32px;

      .label {
        position: absolute;
        font-size: 10px;
        top: -5px;
        left: -5px;
        padding: 5px;
        color: #333;
        border: 1px solid #ccc;
        background-color: #eee;
      }

      .label-bottom {
        top: auto;
        left: auto;
        bottom: -5px;
        right: -5px;
      }
    }
  }

  .legend {
    font-size: 12px;
    letter-spacing: 9px;
    line-height: 18px;
    color: #666;

    i {
      color: #999;
      letter-spacing: 0;
    }
  }

  .legend-right {
    width: 16px;
    word-wrap: break-word;
    vertical-align: middle;
    float: left;
    padding: 5px 20px 5px 15px;

    i {
      height: 28px !important;
    }
  }

  .legend-bottom {
    width: 100%;
    text-align: right;
    padding: 0px 15px;
  }
}

.simple-average-evaluation {
  .titles {
    font-size: 18px;
  }

  .average-div {
    position: relative;
    padding: 8px;
    text-align: center;
    background-color: #f1f1f1;
    font-size: 26px;
    height: 50px;
    border-radius: 6px;

    .caret-badge {
      position: absolute;
      top: 0px;
      font-size: 50px;
      height: 50px;
      line-height: 50px;
      left: 20px;
    }
  }
}

.simple-card-table {
  text-align: center;

  .card-title {
    font-size: 14px;
    padding-top: 5px;
  }

  .filter-field {
    user-select: none;
    cursor: pointer;
  }
}

.simple-lines-table {
  position: relative;
  width: 100%;
  min-height: 160px;
  max-height: 160px;
  overflow: auto;
  align-items: flex-start;

  .simple-line {
    width: calc(100% - 5px);
    display: flex;
    height: 30px;
    padding: 0 0 0 10px;
    overflow: hidden;
    border-radius: 6px;
    line-height: 30px;
    background-color: #f1f1f1;
    margin: 5px 0 10px 0;
    justify-content: space-between;
    font-weight: bold;
    transition: 0.4s;

    &:hover {
      background-color: #ddd;
    }

    .right-label {
      width: 30px;
      background-color: #999;
      color: #fff;
      text-align: center;
      transition: 0.5s;
    }
  }
}

@media screen and (max-width: 980px) {
  .home-banner-container {
    height: 1200px;
  }
}

.home-banner-video {
  position: relative;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
}

.btn-toolbar {
  margin: 0;
}

.home-banner-opacity {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
}

.btn-180 {
  width: 180px;
}

.border-purple {
  border-color: #6b42a9 !important;
}

.btn-purple {
  background-color: #6b42a9;
  color: #fff;
}

.btn-purple:hover,
.btn-purple:active,
.btn-purple:focus {
  color: #fff;
  background-color: #56348a !important;
}

.border-red {
  border-color: #c84b76 !important;
}

.btn-red {
  background-color: #c84b76;
  color: #fff;
}

.btn-red:hover {
  border-color: #fff;
  color: #fff;
}

.border-yellow {
  border-color: #d4ff62 !important;
}

.btn-yellow {
  background-color: #d4ff62;
  color: #222;
}

.btn-yellow:hover {
  border-color: #fff;
  color: #222;
}

.btn-gray {
  background-color: #eee;
  border: 1px solid #ccc;
  color: #222;
}

.btn-gray:hover {
  border-color: #ccc;
  color: #222;
}

.btn-gray:disabled {
  opacity: 0.5;
}

.btn-disabled {
  background-color: #eee;
  border-color: #eee;
  color: #999;
  pointer-events: none;
}

.composed-periods-chart-1100 {
  width: 1100px !important;
}

.participant-workflow-1100 {
  min-width: 1100px !important;
  width: 90vw;

  @media screen and (max-width: 1368px) {
    width: 100%;
  }
}

.exchange-roles-table-1100 {
  min-width: 1100px !important;
  width: 90vw;

  @media screen and (max-width: 1368px) {
    width: 100%;
  }

  thead {
    td {
      transition: 0.3s;

      &:hover {
        background-color: #ddd;
      }
    }
  }
}

.clear {
  clear: both;
}

.simple-tableMaterialButton {
  margin-left: -20px;
  width: 25px !important;
  height: 25px !important;
  min-height: 0px !important;
}

.topBox {
  height: 70px;
  width: 100%;
  border-bottom: 1px solid #ddd;
  background-color: #efefef;
  display: flex;
  align-items: center;
}

.goals-filter-buttons {
  margin: 0px 0px 15px 0px;

  button {
    padding: 5px 10px;
    margin: 0px 10px;
    background-color: #f3f3f3;
    color: #333;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;

    &:hover {
      background-color: #ddd;
    }

    &.active {
      background-color: #ccc;
      font-weight: 500;
    }
  }
}

.simple-month-table {
  width: 100%;
  margin-top: 15px;

  thead {
    tr {
      td {
        border: 1px solid#efefef;
        padding: 10px;
      }

      .defaultTd {
        width: 62px !important;
      }

      .tabletitleTd {
        font-family: monospace;
        width: calc(33.3% - 62px) !important;
      }
    }

    background-color: #eee;
    text-align: center;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
}

.no-internet-connection-bar {
  width: 100%;
  height: 50px;
  position: absolute;
  z-index: 950;
  left: 0;
  right: 0;
  top: -50px;
  opacity: 0;
  background-color: #fff3cd;
  color: #7c6e41;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  transition: 0.4s;

  &.active {
    top: 50px;
    opacity: 1;
  }

  .icon-div {
    margin-right: 10px;
    position: relative;
    width: 20px;
    height: 40px;
    font-size: 18px;

    .wifi {
      position: absolute;
      top: 11px;
      left: 0;
    }

    .slash {
      position: absolute;
      top: 12px;
      left: 1px;
      color: #fff;
      font-size: 17px;
      transform: rotate(4deg);
    }

    .slash2 {
      position: absolute;
      top: 10px;
      left: 1px;
      font-size: 17px;
      opacity: 0.8;
      transform: rotate(4deg);
    }
  }

  .reconnect {
    display: block;
    padding: 0px 5px;
    cursor: pointer;
  }
}

.custom-transition {
  transition: 0.4s;
  overflow: hidden;
}

.MuiMenuItem-root {
  font-size: 14px !important;
}

.small-font {
  font-size: 10px !important;
}

.simple-table {
  margin: 15px 0px 30px 0px;
  width: 100%;
  font-size: 12px;

  &.horizontal-scroll {
    width: fit-content;
    max-width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
    display: block;
  }

  thead {
    background-color: #eee;
    text-align: center;

    tr:hover {
      background-color: #eee !important;
    }
  }

  .thead {
    background-color: #eee !important;
    &:hover {
      background-color: #eee !important;
    }
  }

  tr {
    &:hover {
      background-color: #ddd !important;
    }

    &.block-hover:hover {
      background-color: transparent !important;
    }

    &.block-white-space td {
      white-space: nowrap !important;
    }

    &.no-border-td td {
      border: none;
    }

    &.small-padding td {
      padding: 0px 15px;
      height: 40px;
    }

    &.small-font {
      font-size: 10px;
    }

    &.border-bottom-division td {
      border-bottom: 2px solid #ccc;
    }

    &.disabled-row td {
      opacity: 0.6;
    }

    &.darker-border-top {
      td {
        border-top: 2px solid #ccc;
      }
    }

    &.darker-border-bottom {
      td {
        border-bottom: 2px solid #ccc;
      }
    }
  }

  td {
    border: 1px solid #ddd;
    padding: 7px;

    &.titled {
      background-color: #eee;
      border: 1px solid #ccc;
      white-space: nowrap;
    }

    &.hover-titled:hover {
      background-color: #ccc;
    }

    &.action-collumn {
      cursor: pointer;

      &:hover {
        background-color: #ddd;
      }
    }
  }
}

.react-bs-table-container {
  .react-bs-table table td,
  .react-bs-table table th {
    white-space: pre-line;
    text-overflow: unset;
    overflow-wrap: break-word;
  }
}

.btn {
  &:focus {
    outline: none !important;
  }

  &.btn-app {
    margin: 10px 15px 10px 0;
    border: 1px solid #ddd;
    padding-top: 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .badge {
      position: absolute;
      top: -3px;
    }
  }
}

.navbar-nav {
  .messages-menu {
    .dropdown-menu {
      li {
        .menu {
          li {
            a {
              h4,
              p {
                margin: 0px;
              }
            }
          }
        }
      }
    }
  }
}

.info-box-number {
  height: 25px;
}

.info-box {
  .info-box-content {
    h3 {
      font-weight: 800;
    }

    p {
      font-size: 18px;
    }

    .info-box-half-content {
      float: left;
      width: 50%;
      padding: 0;
    }
  }
}

.small-box {
  h3 {
    a {
      color: $color_black_haze_approx_dark;

      &:hover {
        color: $color_black_haze_approx_dark;
      }
    }
  }
}

.shadow-nao-iniciado,
.shadow-nao-iniciada {
  box-shadow: 0 0 4px 0px rgba(128, 128, 128, 0.6);
}

.shadow-iniciado {
  box-shadow: 0 0 4px 0px rgba(30, 144, 255, 0.6);
}

.shadow-em-andamento,
.shadow-superada {
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.6);
}

.shadow-parcialmente-realizado,
.shadow-parcialmente-atingida {
  box-shadow: 0 0 4px 0px rgba(218, 165, 32, 0.6);
}

.shadow-realizado,
.shadow-atingida {
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.6);
}

.shadow-cancelado,
.shadow-nao-atingida {
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.6);
}

.shadow-cancelada {
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.6);
}

.shadow-insight {
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.6);
}

.bg-nao-iniciado,
.bg-nao-iniciada {
  background: grey;
  color: #fff;
}

.bg-iniciado {
  background: dodgerblue;
  color: #fff;
}

.bg-em-andamento,
.bg-superada {
  background: #6495ed;
  color: #fff;
}

.bg-parcialmente-realizado,
.bg-parcialmente-atingida {
  background: #ffc107;
  color: #fff;
}

.bg-realizado,
.bg-atingida {
  background: #0c9247;
  color: #fff;
}

.bg-cancelado,
.bg-nao-atingida {
  background: #f54e4e;
  color: #fff;
}

.bg-cancelada {
  background: #333;
  color: #fff;
}

.bg-insight {
  background: $color_black_haze_approx_dark;
  color: #fff;
}

.text-nao-iniciado,
.text-nao-iniciada {
  color: grey;
}

.text-iniciado {
  color: dodgerblue;
}

.text-em-andamento,
.text-superada {
  color: blue;
}

.text-parcialmente-realizado,
.text-parcialmente-atingida {
  color: goldenrod;
}

.text-realizado,
.text-atingida {
  color: forestgreen;
}

.text-cancelado,
.text-nao-atingida {
  color: darkred;
}

.text-cancelada {
  color: black;
}

.text-insight {
  color: $color_black_haze_approx_dark;
}

.box {
  .box-header {
    .far,
    .fas {
      margin: 0 5px 0 0;
    }

    a {
      color: #000;
      font-weight: bold;
    }
  }
}

.dont-break-out {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.element-inline {
  display: inline-block;
  vertical-align: middle;
}

.ReactModal__Content {
  top: 20%;
}

.ReactModal__Overlay {
  z-index: 1030;
}

.s-alert-box {
  z-index: 1031;
}

.btn-social:hover {
  color: $white_dark !important;
}

.tab-content {
  padding-top: 10px;
  position: relative;
}

.input-tags {
  border-radius: 4px;
  margin: 2px 5px 0 0;
  height: 30px;

  div {
    margin: -1px;
    padding: 0 2rem 0 0;
  }

  &.theme_root__3tk {
    padding: 0 10px 0 5px;
  }

  .theme_tag__25f {
    padding: 0 1rem 0 1rem;
  }

  .theme_inputContainer__1zi {
    margin: 0 0.5rem 0 0.5rem;
    height: 30px;

    input {
      height: 30px;
    }
  }
}

.inviteForm {
  .form-group {
    textarea {
      &.form-control {
        resize: vertical;
        min-height: 100px;
      }
    }
  }
}

.table > {
  thead > tr >,
  tbody > tr >,
  tfoot > tr > {
    th,
    td {
      vertical-align: middle !important;

      &:focus {
        outline: none;
      }
    }
  }
}

.answer-line {
  padding: 5px 10px;
}

.act-insight {
  float: left;
  margin: 11px 10px 0;
  cursor: pointer;
  color: $color_black_haze_approx_dark;
}

.act-manage-insight {
  cursor: pointer;
}

.gray-link {
  color: #737f37;
  font-weight: bold;
  cursor: pointer;
}

.average_tool_answer {
  font-weight: bold;
  display: flex;
  justify-content: center;

  .badge {
    margin: 0 2px;
  }

  &.swot {
    margin-top: 17px;
  }
}

.average_tool_question {
  width: 68px !important;
}

.badge_tool {
  div {
    width: 100px;
  }
}

.politicalFactorColor {
  background-color: #6666ff !important;
}

.economicalFactorColor {
  background-color: #66ffff !important;
}

.socialFactorColor {
  background-color: #ff9933 !important;
}

.technologicalFactorColor {
  background-color: #999900 !important;
}

.ambientalFactorColor {
  background-color: #66ff66 !important;
}

.legalFactorColor {
  background-color: #7f00ff !important;
}

.pipe_tool_green {
  background-color: #00b050 !important;
}

.pipe_tool_red {
  background-color: tomato !important;
}

.physicalAdvanceColor {
  background-color: #499df5 !important;
}

.financialAdvanceColor {
  background-color: tomato !important;
}

.effortAdvanceColor {
  background-color: #00b050 !important;
}

.sb-avatar__text {
  margin-right: 15px;
}

.fixedWidth {
  width: 42px !important;
}

.responsiveBox {
  display: flex;
}

.badge {
  &.answer {
    margin: 0 15px;
  }

  &.badge-status {
    display: inline-block;
    min-width: 170px;
    height: 20px;
    margin: 0;
    line-height: 16px;
  }
}

.container_circle_progress {
  float: right;

  .rc-progress-circle {
    width: 28px;
    padding: 3px 0 0 0;
    margin: 0;
    float: left;
    cursor: pointer;
  }

  .circle_progress_label {
    font-size: 12px;
    padding: 8px 7px 0 5px;
    float: left;
    font-weight: bold;
    cursor: pointer;
  }
}

.simple-active-status-badge {
  display: flex;
  height: 20px;
  line-height: 20px;
  align-items: center;
  font-weight: 500;
  padding-right: 10px;
  font-size: 14px;
  border-radius: 9px;
  border: 1px solid #333;
  color: #333;

  .status {
    position: relative;
    left: -1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #333;
  }
}

.fake-material-input {
  white-space: nowrap;
  min-width: 200px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  text-align: left;
}

.simple-remove-button {
  padding: 0px 8px;
  height: 35px;
  background-color: transparent;
  color: tomato;
  border: 1px solid #ccc;
  font-size: 14px;
}

.MuiTooltip-tooltip {
  font-size: 14px !important;
}

.MuiInputLabel-root {
  padding: 0 3px 0 0 !important;
  background: #fff;
}

.comments-display {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-flow: row wrap;

  .box-comments {
    background-color: transparent !important;
    max-height: 800px;
    overflow-x: auto;
  }

  .text-moment {
    text-align: right;
    color: #9f9f9f !important;
  }

  .hide-display-user-timeline {
    overflow: hidden !important;
  }

  .display-user-timeline {
    position: relative;
    width: 100%;

    .username-timeline {
      height: 30px;
      line-height: 30px;
      font-weight: 500;
      font-size: 16px;
    }

    .timeline-icon {
      position: absolute;
      height: 30px;
      width: 30px;
      line-height: 30px;
      text-align: center;
      top: 35%;
      left: -73px;
      color: #fff;
      z-index: 1000;
    }

    &:after {
      content: "";
      position: absolute;
      width: 3px;
      left: -60px;
      top: 0px;
      height: 100vh;
      background-color: #e1e1e1;
    }

    &:before {
      content: "";
      width: 30px;
      height: 30px;
      position: absolute;
      left: -73px;
      top: 35%;
      z-index: 1;
      border-radius: 30px;
      background-color: #a06af3;
    }
  }

  .box-user-timeline {
    margin-left: 13px;
    line-height: 25px;
  }

  .nav-tabs-custom {
    margin: 0px !important;
  }

  .box-footer {
    border-top: 4px dotted #eee !important;
    border-bottom: 4px dotted #eee !important;
    margin-top: 15px;
    padding-top: 15px;
  }

  .box-comment-timeline {
    display: flex;
    padding: 10px 0px 25px 0px;
    margin: 0;
    position: relative;

    .date {
      position: absolute;
      height: 45px;
      bottom: -20px;
      right: 0px;
      text-align: right;
      font-size: 12px;
    }
  }

  .profile {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row wrap;

    .username {
      line-height: 30px;
      font-weight: 500;
      margin-left: 5px;
    }
  }

  .box-comment {
    background-color: #fdfdfd;
    padding: 15px;
    position: relative;

    .comment-text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0px;
      flex-flow: row wrap;

      .comment-line {
        margin-bottom: 15px;
        display: inline-block;
        width: 100%;

        .date {
          text-align: right;
          color: #666;
          font-size: 12px;
          padding: 5px;
        }

        .editable-field {
          width: calc(100% - 30px);
          margin: 0px 30px 0px 0px;
          padding: 10px;
          background-color: #f3f3f3;
          border-radius: 14px;
          display: inline-block;
          border: 1px solid #eee;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 40%;
            width: 0;
            height: 0;
            border: 20px solid transparent;
            border-left-color: #f3f3f3;
            border-right: 0;
            border-top: 0;
            margin-top: -10px;
            margin-right: -20px;
          }
        }
      }

      .comment-timeline {
        display: inline-block;
        width: 100%;
      }
    }

    .comment-classifications {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .comment-badge {
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        padding: 0px 5px;
        border-radius: 5px;
        font-weight: bold;
      }
    }

    &.self-comment {
      .comment-classifications {
        margin: 0px 35px;
        justify-content: flex-start;
      }

      .comment-text .profile {
        justify-content: flex-start;
      }

      .comment-line .editable-field {
        width: calc(100% - 30px);
        margin: 0px 0px 0px 30px;
      }

      .comment-line .editable-field:after {
        content: "";
        position: absolute;
        left: 0;
        top: 40%;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-right-color: #f3f3f3;
        border-left: 0;
        border-top: 0;
        margin-top: -10px;
        margin-left: -20px;
      }
    }
  }
}

.box-comment {
  margin: 0;

  .comments_list {
    .comment_header {
      .comment_owner {
        .sb-avatar {
          margin: 0 5px 0 0;
        }
      }

      .comment_date {
        font-size: 10px;
        color: #888;
        padding-top: 5px;
        text-align: right;
      }
    }

    .comment_body {
      padding: 7px 0;
    }
  }
}

@media screen and (max-width: 996px) {
  .box-profile-field {
    padding: 0px !important;
  }
}

.box-activities {
  .rc-progress-circle {
    width: 28px;
    padding: 0;
    margin: 0;
  }
}

.container_kanban {
  white-space: nowrap;
  margin-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px 0;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  .cardContainer {
    padding: 0 10px 0 0 !important;
  }

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 8px grey;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color_black_haze_approx_dark;
  }

  .list_status {
    background: white;
    border-top: none;
    border-radius: 3px;
    width: 100%;
    height: 350px;

    .title_list_status {
      font-weight: 600;
      color: #fff;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 2px;

      &.nao_iniciado,
      &.nao_iniciada {
        background: grey;
      }

      &.iniciado {
        background: dodgerblue;
      }

      &.em_andamento,
      &.superada {
        background: cornflowerblue;
      }

      &.parcialmente_realizado,
      &.parcialmente-atingida {
        background: goldenrod;
      }

      &.realizado,
      &.atingida {
        background: seagreen;
      }

      &.cancelado,
      &.nao_atingida {
        background: tomato;
      }

      &.cancelada {
        background: #333;
      }
    }

    ul.container_list_status {
      padding: 10px 10px 48px 10px;

      li {
        list-style: none;
        margin: 5px !important;

        &.card_list_status {
          height: 30px;
          margin-bottom: 15px !important;
          box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
          border-radius: 3px;

          .kanbanBadge {
            width: 100%;
            background-color: whitesmoke;
            height: 100%;
            padding-top: 5px;
            padding-left: 10px;
            display: flex;
          }

          .kanbanText {
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.tools {
  .menu_mob_tools {
    display: none;
  }

  .tab-content {
    #tool {
      .cenary_container {
        .nav-tabs-custom {
          box-shadow: none;
          -webkit-box-shadow: none;

          .container_tabs_horizontal {
            position: relative;

            .nav_tabs_arrow {
              float: left;
              width: 33px;
              padding: 14px 10px;
              background: #fff;
              position: relative;
              cursor: pointer;

              &:hover {
                text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
              }

              &:active {
                color: $color_black_haze_approx_dark;
              }

              i {
                font-size: 20px;
              }

              &.left {
                padding-right: 15px;
              }

              &.right {
                padding-left: 15px;
                float: right;
              }

              &.disable {
                color: #999;

                &:hover {
                  text-shadow: none;
                }

                &:active {
                  color: #999;
                }
              }
            }

            .nav_slide_container {
              float: left;
              width: calc(100% - 58px - 66px);
              height: 52px;
              overflow: hidden;
              display: inline-flex;
              position: relative;
            }

            .nav {
              position: absolute;

              &.nav-tabs {
                transition: all 0.1s linear;
                height: 52px;
                width: 100%;

                > li {
                  border-top: 3px solid transparent;
                  border-bottom: 1px solid #f4f4f4;

                  &:hover {
                    background: transparent;
                  }

                  &.active {
                    border-top: 3px solid $color_black_haze_approx_dark;
                    border-left: 1px solid #f4f4f4;
                    border-right: 1px solid #f4f4f4;
                    border-bottom: 1px solid #fff;

                    > a {
                      background: #fff;
                    }
                  }

                  > a {
                    &:hover {
                      color: #999;
                      background: transparent;
                      border-color: transparent;
                    }

                    margin: 0;
                    background: transparent;
                  }
                }
              }
            }

            .add_button_container {
              float: right;
              padding: 4px 0;
            }

            &:after {
              display: block;
              content: "";
              clear: both;
            }
          }

          .nav {
            &.nav-mob {
              display: none;
            }

            .btn-archive-answer {
              margin: 5px 10px;
            }

            .btn-action {
              background: none;
              border: 0;
              box-shadow: none;
              padding: 3px 2px 0;
              margin: 0 0 0 10px;
            }

            .average_tool {
              margin: 2px 0 0 15px;
            }

            .average_tool_table {
              border-radius: 30px;
            }
          }

          .tab-content {
            border: 1px solid #f2f2f2;
            margin: -2px 0 0 0;

            .container_boxes_right {
              transition: all 0.2s linear 0.3s;
              overflow: hidden;

              &.cenary {
                .box_resume_cenary {
                  .box-header {
                    .btn-close-cenary-resume {
                      cursor: pointer;
                    }
                  }

                  .box-body {
                    .cenary_resume_details {
                      .cenary_resume_details_container {
                        margin: 0 0 15px;

                        &.date {
                          .date_info {
                            margin: 15px 0;
                          }
                        }

                        &.responsible {
                          .members_list {
                            padding-right: 0;
                            margin: 8px 0 0;
                            float: right;

                            > div {
                              cursor: pointer;

                              > a {
                                margin: 0 7px 0 0;
                              }
                            }
                          }

                          .search_responsible {
                            margin: 5px 0 0;
                          }
                        }

                        .label {
                          display: inline-block;
                          min-width: 115px;
                          padding: 0;

                          h5 {
                            font-weight: bold;
                            text-align: left;
                            margin: 15px 0;
                            color: #000;
                            padding: 0;
                          }
                        }

                        .info {
                          padding: 0 5px;
                          display: inline-block;
                        }

                        .manage_members {
                          margin: 13px 0 0;
                          display: block;
                          cursor: pointer;
                        }

                        .members_list {
                          > a {
                            margin: 0 5px 0 0;
                            display: inline-block;
                            cursor: default;

                            &:last-child {
                              margin: 0;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .theme_container {
              padding: 0;

              .theme_accordion_container {
                .theme_accordion {
                  &.panel {
                    border: 1px solid #e2e4e6;
                    border-top: 2px solid #b1d400;
                    box-shadow: 0 0 12px -3px rgba(0, 0, 0, 0.3);

                    .panel-heading {
                      background: #f4ffba;
                      border-bottom: 1px solid #e2e4e6;
                      padding: 0;

                      .btn-delete {
                        display: inline-block;
                        background: none;
                        font-size: 14px;
                        padding: 2px 5px;
                        margin: 2px;
                        cursor: pointer;
                        outline: none;
                      }

                      .btn_toggle-accordion {
                        padding: 2px 5px;
                        margin: 0 3px;
                        color: #aaa;
                      }

                      .btn-action {
                        background: none;
                        border: 0;
                        box-shadow: none;
                        outline: none;
                        padding: 4px 2px 0;
                        margin: 0 0 0 12px;
                      }
                    }
                  }

                  .question_container {
                    .question_accordion {
                      &.panel {
                        > .panel-heading {
                          background: #f5f5f5;
                        }
                      }

                      .answer_container {
                        .row_answer {
                          margin-bottom: 1px;
                          background: #fff;
                          border-bottom: 1px solid #ccc;
                          border-radius: 3px;
                          height: 40px;
                          display: flex;
                          justify-content: space-between;
                          align-items: center;

                          &:hover {
                            background: #edeff0 !important;
                            border-bottom-color: #d6dadc;
                          }

                          .answer-line {
                            &::before {
                              content: "";
                              display: table;
                            }

                            &::after {
                              clear: both;
                            }
                          }

                          .input-field {
                            display: flex;
                            width: 70%;
                            height: 100%;
                            cursor: pointer;
                          }

                          .informations {
                            display: flex;
                            align-items: center;
                            margin: 0 8px 0 0;

                            .btn {
                              padding: 0;
                            }
                          }

                          .btn-action {
                            background: none;
                            border: 0;
                            box-shadow: none;
                            outline: none;
                            padding: 4px 2px 0;
                            margin: 5px 8px 0 15px;
                          }

                          .btn-delete-answer {
                            font-size: 14px;
                            margin: 10px;
                            cursor: pointer;
                            float: right;
                            outline: none;
                          }
                        }

                        .add-answer-container {
                          padding: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    #management {
      .container-info-box {
        /* .newcol-5 {
                    width: 20%;
                    padding: 0 15px;
                    float: left; */

        .info-box {
          min-height: 75px;

          .info-box-icon {
            line-height: 75px;
            height: 75px;
            width: 75px;
            font-size: 40px;
          }

          .info-box-content {
            margin-left: 75px;
            padding: 5px 5px 5px 10px;

            h3 {
              margin: 5px 0;
            }
          }
        }

        // }
      }
    }

    #timeline {
      ul.timeline {
        li.time-label {
          .fa {
            z-index: 1;
          }

          ul.group-timeline {
            margin: 15px 0 0;

            li {
              .timeline-item {
                .timeline-body {
                  textarea {
                    resize: none;
                    height: 80px;
                  }
                }
              }
            }
          }

          &:first-child {
            > ul {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.single_image {
  > a {
    margin: 0 5px 0 0;
    display: inline-block;
    cursor: default;
  }
}

.popover {
  z-index: 810;
  max-width: auto;
  min-width: 200px;

  .popover-content {
    padding: 5px 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 5px;
        margin: 5px 0;

        .item-menu-action {
          background: none;
          box-shadow: none;
          border: 0;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .container_visibility {
          margin: 10px 0 0;
        }
      }
    }
  }
}

.modal-container {
  position: relative !important;
}

.modal-container .modal,
.modal-container .modal-backdrop {
  position: absolute !important;
}

.modal-insight {
  padding: 0px 0px;

  .insight-view {
    .insight-header-data {
      border-bottom: 2px solid #ccc;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .insight-scroll-container {
      width: 100%;
      height: calc(100% - 44px);
      overflow: scroll;
      overflow-x: hidden;
    }
  }
}

.modal_member {
  .modal-dialog {
    .modal-content {
      .modal-body {
        h4 {
          margin: 25px 0 10px;
        }

        .container_members_cenary {
          .item_member_cenary {
            margin: 5px 0;
            padding: 5px;

            &:hover {
              background: #f2f2f2;
            }

            > div {
              > div {
                padding-top: 5px;

                &:last-child {
                  padding-top: 0;
                }

                .avatar_image {
                  margin: 0 10px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.collapse_container_teste {
  border: 1px solid #e2e4e6;
  box-shadow: 0 0 12px -3px rgba(0, 0, 0, 0.3);
  margin-top: 5px;
  border: 1px solid #e2e4e6;
  box-shadow: 0 0 12px -3px rgba(0, 0, 0, 0.3);
  margin-top: 5px;

  .collapse_header_teste {
    padding: 5px 0px;
    background-color: #f4ffba;
    border-bottom: 2px solid #e2e4e6;
    border-top: 2px solid #b1d400;
    margin-left: 0px;
    margin-right: 0px;

    .collapse_header_link {
      display: flex;
      align-items: center;
      color: #464646;

      h5 {
        padding-left: 5px;
        display: flex;
        align-items: center;
        font-size: 16px;
      }
    }
  }

  .collapse_content_item {
    border: 2px solid #e2e4e6;
    margin-top: 2px;
  }
}

.header-faq {
  display: flex;
  align-items: center;
}

.modal-templates {
  width: 1260px;
}

.modal-insight-details {
  position: fixed;
  top: 20px;
  width: 95% !important;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: 0s !important;

  @media (max-width: 425px) {
    width: 405px;
  }

  @media (max-width: 375px) {
    width: 355px;
  }

  @media (max-width: 320px) {
    width: 300px;
  }

  .modal-insight {
    .box-body {
      padding: 20px 10px;

      dl {
        float: right;
        margin: 0 0 10px;

        .search_responsible {
          position: relative;
          z-index: 2;
        }

        .react-autosuggest__container {
          .react-autosuggest__input {
            display: block;
            outline: none;
            width: 100%;
            height: 33px;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857;
            color: #555555;
            background-color: #fff;
            background-image: none;
            border: 1px solid #ccc;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            -webkit-transition: border-color ease-in-out 0.15s,
              box-shadow ease-in-out 0.15s;
            -o-transition: border-color ease-in-out 0.15s,
              box-shadow ease-in-out 0.15s;
            transition: border-color ease-in-out 0.15s,
              box-shadow ease-in-out 0.15s;

            &.react-autosuggest__input--open {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }

            &:first-child {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }

          .input-group {
            .react-autosuggest__input {
              display: block;
              outline: none;
              width: 100%;
              height: 33px;
              padding: 6px 12px;
              font-size: 14px;
              line-height: 1.42857;
              color: #555555;
              background-color: #fff;
              background-image: none;
              border: 1px solid #ccc;
              border-radius: 4px;
              -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
              box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
              -webkit-transition: border-color ease-in-out 0.15s,
                box-shadow ease-in-out 0.15s;
              -o-transition: border-color ease-in-out 0.15s,
                box-shadow ease-in-out 0.15s;
              transition: border-color ease-in-out 0.15s,
                box-shadow ease-in-out 0.15s;

              &.react-autosuggest__input--open {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }

              &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
          }

          .react-autosuggest__suggestions-container {
            ul.react-autosuggest__suggestions-list {
              li.react-autosuggest__suggestion {
                .item_search_responsible {
                  padding: 5px;

                  a {
                    margin: 0 7px 0 0;
                  }
                }

                .container-fluid {
                  > div {
                    padding: 10px 0;

                    .avatar_image {
                      margin: 0 10px 0 0;
                    }
                  }
                }
              }
            }
          }
        }

        .bg-insight-modal {
          background-color: #6b42a9;
          color: #fff;

          button {
            background-color: transparent;
            color: #fff;
            opacity: 1;
          }
        }

        .modal-insight {
          .products-list {
            .product-info {
              padding-left: 0;
              margin-left: 0;
            }

            .product-title {
              padding: 8px 0;
              height: 40px;
              line-height: 18px;
            }

            .product-description {
              display: block;
              overflow: auto;
              white-space: inherit;
              word-wrap: break-word;
              padding-left: 4px;
              padding: 4px;
            }

            .score-badge {
              line-height: 20px;
              min-width: 20px;
              height: 20px;
              padding: 0px;
              font-size: 100px;
            }
          }

          .info-icon {
            border-radius: 5px;
            display: block;
            float: left;
            height: 45px;
            width: 45px;
            text-align: center;
            font-size: 30px;
            line-height: 45px;
            background: rgba(0, 0, 0, 0.2);
          }

          &.modal-body {
            padding: 0 15px;
          }
        }

        .btn {
          &:focus,
          &:active:focus,
          &:active.focus,
          &.active:focus &.active.focus {
            outline: none;
          }
        }

        #companyTabs {
          .container-filter {
            .container-filter-buttons {
              float: left;
            }

            .dl-horizontal {
              margin: 10px 0;

              @media (min-width: 768px) {
                dd,
                dt {
                  height: 36px;
                  line-height: 30px;
                }
              }

              @media (max-width: 425px) {
                dd {
                  padding: 0 0 12px;
                }
              }
            }

            #companyTabs {
              .container-filter {
                .container-filter-buttons {
                  float: left;
                }

                .container-filter-views {
                  float: right;
                  margin: 25px 0;
                }
              }

              .container-body {
                .table-workspace-overview tr th {
                  text-transform: capitalize;
                }

                .sc-bdVaJa {
                  a,
                  a:after {
                    background-color: $color_black_haze_approx;
                    color: #464646;
                    font-weight: bold;
                    text-transform: capitalize;
                    box-shadow: 2px -2px 0px 0px #464646;
                  }

                  a:hover,
                  a:hover:after {
                    background-color: $color_black_haze_approx_dark;
                  }

                  a:last-child,
                  a:last-child:after {
                    background-color: #464646;
                    color: #fff;
                  }
                }
              }
            }

            .info-box-just-text {
              font-size: 2.5rem;
            }

            .info-box-icon-right {
              float: right;
            }

            .info-box-content-left {
              display: inline-block;
              margin-left: 0;
              width: 63%;
            }

            .link-font-12 {
              font-size: 1.2rem;
            }

            .progress-description {
              .badge {
                margin-top: 0.4rem;
              }
            }

            .termsx {
              overflow-y: scroll;
              height: 300px;
              width: 100%;
              border: 1px solid #ddd;
              padding: 10px;

              .title {
                font-size: xx-large;
              }

              .subtitle {
                font-size: x-large;
              }

              .body {
                font-size: small;
              }
            }

            .table-evaluation {
              background-color: #f6f6f6;
              border-radius: 5px;
              color: #222;

              th {
                background-color: #f1f1f1;
              }

              tr:hover {
                background-color: #ddd;
              }

              td {
                transition: 0.3s;
              }

              thead th {
                padding-top: 10px;
                padding-bottom: 10px;
              }

              thead th:first-child {
                padding-left: 10px;
              }

              thead th:last-child {
                padding-right: 10px;
              }

              @media screen and (max-width: 1475px) {
                .tools {
                  .tab-content {
                    #management {
                      .container-info-box {
                        .newcol-5 {
                          width: 20%;

                          tbody td:first-child:hover {
                            background-color: transparent;
                            cursor: default;
                            color: #222;
                          }

                          tbody td:hover {
                            background-color: #ccc;
                            color: #222;
                            cursor: pointer;
                          }

                          tfoot td {
                            padding: 10px;
                            background-color: #f1f1f1;
                          }
                        }

                        .readOnly tbody td:hover {
                          cursor: text !important;
                        }

                        .flex-cards {
                          display: flex;
                          justify-content: space-between;
                          text-align: center;
                          margin: 15px 0;
                        }

                        .flex-card {
                          border-radius: 5px;

                          .card-title {
                            padding: 10px;
                            margin: 0px;
                          }

                          .card-content {
                            padding: 20px;
                          }

                          .card-drag-item {
                            padding: 6px;
                            margin: 20px 0;
                            background-color: #eee;
                            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
                          }
                        }

                        .card-container {
                          border: 1px solid #ccc;
                          border-radius: 6px;
                          margin-top: 5px;
                          margin-bottom: 25px;

                          .card-title {
                            margin: 0px;
                            padding: 20px;
                          }

                          .card-body {
                            padding: 5px 20px;
                            background-color: #f6f6f6;

                            .card-row {
                              padding: 10px 0;

                              .row-title {
                                color: #333;
                                margin: 0px;
                                padding-left: 14px;
                                border-left: 5px solid #666;
                                margin-left: -20px;
                              }

                              .row-table {
                                width: 100%;
                                border-bottom: 1px solid #ccc;

                                .table-title {
                                  margin: 18px 0px 10px 0px;
                                }

                                table {
                                  width: 100%;
                                  margin: 15px 0px 25px 0px;
                                  background-color: #f1f1f1;

                                  thead {
                                    background-color: #eee;
                                  }

                                  thead th {
                                    padding: 15px;
                                  }

                                  thead th:hover {
                                    background-color: #e1e1e1;
                                  }

                                  tbody td {
                                    padding: 15px;
                                  }

                                  tbody td:hover {
                                    background-color: #e3e3e3;
                                  }
                                }
                              }
                            }
                          }
                        }

                        @media screen and (max-width: 1475px) {
                          .tools {
                            .tab-content {
                              #management {
                                .container-info-box {
                                  .newcol-5 {
                                    width: 20%;

                                    .info-box {
                                      .info-box-icon {
                                        line-height: 75px;
                                        height: 75px;
                                        width: 45px;
                                        font-size: 30px;
                                      }

                                      .info-box-content {
                                        margin-left: 40px;

                                        h3 {
                                          margin: 5px 0;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }

                          .info-box-content-left {
                            width: 68%;
                          }

                          .info-box-icon {
                            width: 84px;
                          }
                        }

                        @media screen and (max-width: 1280px) {
                          .tools {
                            > .nav {
                              display: none;
                            }

                            .menu_mob_tools {
                              display: block;

                              .nav_menu_mob_tools {
                                display: none;
                                position: absolute;
                                height: auto;
                                width: auto;
                                padding: 8px;
                                margin: 8px 0 0 0;
                                background: #fff;
                                border-radius: 4px;
                                box-shadow: 0px 0px 13px -2px rgba(0, 0, 0, 0.4);
                                right: -1px;
                                z-index: 2;
                                outline: none;

                                > li {
                                  float: none;
                                  list-style: inside none;

                                  &:hover {
                                    background: $color_black_haze_approx_dark;

                                    a {
                                      color: #fff;
                                    }
                                  }

                                  a {
                                    padding: 5px;
                                    display: block;
                                  }
                                }
                              }

                              button {
                                position: relative;
                                float: right;
                                margin: 5px;
                                outline: none;
                              }
                            }

                            .tab-content {
                              #tool {
                                .cenary_container {
                                  .nav-tabs-custom {
                                    .container_tabs_horizontal {
                                      display: none;
                                    }

                                    .nav {
                                      &.nav-mob {
                                        display: block;

                                        > li.container_menu_mob {
                                          position: relative;

                                          button {
                                            margin: 5px;

                                            > i {
                                              font-size: 14px;
                                              cursor: pointer;
                                            }
                                          }

                                          .menu_mob_hidden {
                                            display: none;
                                            position: absolute;
                                            height: auto;
                                            padding: 8px;
                                            background: #fff;
                                            border-radius: 4px;
                                            box-shadow: 0px 0px 13px -2px rgba(0, 0, 0, 0.4);
                                            right: 0;
                                            z-index: 1;

                                            > li {
                                              list-style: inside none;

                                              &:hover {
                                                background: $color_black_haze_approx_dark;

                                                a {
                                                  color: #fff;
                                                }
                                              }

                                              a {
                                                padding: 5px;
                                                display: block;

                                                .average_tool {
                                                  margin: 0 0 0 10px;
                                                }
                                              }
                                            }

                                            > button {
                                              margin: 5px 0;
                                              padding: 0;
                                            }
                                          }
                                        }

                                        > li.cenary_tab {
                                          a {
                                            .average_tool {
                                              margin: -1px 0 0 15px;
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }

                              #management {
                                .container-info-box {
                                  .newcol-5 {
                                    width: 33%;

                                    &:nth-child(2),
                                    &:nth-child(5) {
                                      width: 34%;
                                    }

                                    .info-box {
                                      min-height: 75px;

                                      .info-box-icon {
                                        line-height: 75px;
                                        height: 75px;
                                        width: 75px;
                                        font-size: 40px;
                                      }

                                      .info-box-content {
                                        margin-left: 75px;

                                        h3 {
                                          margin: 5px 0;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }

                          .info-box-content-left {
                            width: 63%;
                          }
                        }

                        @media screen and (max-width: 1199px) {
                          .clear_lg {
                            clear: both;
                          }

                          .info-box-content-left {
                            width: 52%;
                          }
                        }

                        @media screen and (max-width: 1023px) {
                          .info-box-content-left {
                            width: 63%;
                          }

                          .clear_mob {
                            clear: both;
                          }

                          .tools {
                            .tab-content {
                              #tool {
                                .cenary_container {
                                  .nav-tabs-custom {
                                    .tab-content {
                                      .theme_container {
                                        .theme_accordion_container {
                                          .theme_accordion {
                                            .question_container {
                                              .question_accordion {
                                                .answer_container {
                                                  .row_answer {
                                                    .container_circle_progress {
                                                      float: left;
                                                      display: flex;
                                                      flex-direction: row-reverse;
                                                      height: 50px;

                                                      .rc-progress-circle {
                                                        margin: 10px 0 10px 5px;
                                                      }

                                                      .circle_progress_label {
                                                        margin: 10px 0;
                                                      }
                                                    }

                                                    .btn-delete-answer {
                                                      margin: 0 10px;
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }

                        @media screen and (max-width: 991px) {
                          .box-card-goal {
                            display: flex;
                            flex-wrap: wrap;
                          }

                          .tools {
                            .tab-content {
                              #tool {
                                .cenary_container {
                                  .nav-tabs-custom {
                                    .tab-content {
                                      .container_boxes_right {
                                        &.cenary {
                                          .box_resume_cenary {
                                            .box-body {
                                              .cenary_resume_details {
                                                .cenary_resume_details_container {
                                                  &.responsible {
                                                    .members_list {
                                                      float: none;
                                                      margin: 0 0 0 5px;
                                                    }
                                                  }

                                                  &.members {
                                                    .members_list {
                                                      margin: 0 0 0 5px;
                                                    }
                                                  }

                                                  .label {
                                                    padding: 0 0 0 5px;
                                                  }

                                                  > div {
                                                    > div {
                                                      text-align: left;

                                                      .manage_members {
                                                        margin: 0 0 10px 0;
                                                        padding: 0 0 0 5px;
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }

                              #management {
                                .container-info-box {
                                  .newcol-5 {
                                    width: 50%;

                                    &:nth-child(2),
                                    &:nth-child(5) {
                                      width: 50%;
                                    }
                                  }
                                }
                              }
                            }
                          }

                          .info-box-content-left {
                            width: 85%;
                          }
                        }

                        @media screen and (max-width: 767px) {
                          .main-header {
                            .navbar {
                              .navbar-custom-menu {
                                float: none;

                                .nav {
                                  float: none;

                                  > li {
                                    > a {
                                      padding: 13px;
                                      line-height: 26px;
                                    }
                                  }
                                }
                              }
                            }
                          }

                          .tools {
                            .tab-content {
                              #tool {
                                .cenary_container {
                                  .nav-tabs-custom {
                                    .tab-content {
                                      .box-timeline {
                                        .timeline_comment_data {
                                          text-align: right;
                                          padding-bottom: 5px;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }

                        @media screen and (max-width: 480px) {
                          .tools {
                            .tab-content {
                              #management {
                                .container-info-box {
                                  .newcol-5 {
                                    width: 100%;

                                    &:nth-child(2),
                                    &:nth-child(5) {
                                      width: 100%;
                                    }
                                  }
                                }
                              }
                            }
                          }

                          .info-box-content-left {
                            width: 65%;
                          }
                        }

                        @media screen and (max-width: 425px) {
                          .info-box-content-left {
                            width: 74%;
                          }
                        }

                        @media screen and (max-width: 375px) {
                          .info-box-content-left {
                            width: 70%;
                          }
                        }

                        @media screen and (max-width: 320px) {
                          .info-box-content-left {
                            width: 63%;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.smallMarginLeft {
  margin-left: 20px;
}

.colorRed {
  color: red;
}

.tableEvaluationSwot {
  width: 100%;
  text-align: center !important;
}

.evaluationHeader {
  font-weight: 30px;
}

.evaluationBadgeQuestion {
  font-weight: bold;
}

.progressWrapper {
  width: 120px;
  max-width: 120px;
  height: 30px;
  max-height: 30px;
}

.customThSpace {
  width: 120px;
  max-width: 120px;
  height: 30px;
  max-height: 30px;
}

.firstColumn {
  margin-left: 30px;
}

.evaluationProgressBar {
  background-color: #dc3545 !important;
}

.breadcrumb-max-width {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 1100px) {
    max-width: 150px;
  }
}

.products-list {
  width: 100%;
  border: 1px solid #eee;

  border-radius: 8px;
  padding: 10px 5px 5px 5px;

  li {
    display: flex;
    align-items: center;
    height: 60px;

    .container {
      display: flex;
      width: 100%;
      padding: 0px;
      margin: 0px;

      .badge-field {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;

        .badge {
          border-radius: 50%;
          width: 25px;
          height: 25px;
          line-height: 19px;
          text-align: center;
        }
      }

      .product-info {
        margin-left: 0;
        padding-left: 0;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .product-title {
          width: 400px;
          max-width: 400px;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .product-description {
          font-size: 12px;
          color: #333;
          padding-bottom: 5px;
        }
      }
    }
  }

  button {
    background-color: transparent;
    width: 40px;
    height: 40px;
    line-height: 0px !important;
    text-align: center;
    margin-top: 10px;
  }
}

.info-box-icon {
  font-size: 3rem;
}

.tableEvaluationInsightName {
  h4 {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.tableEvaluationColumnTitle {
  width: 120px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center !important;

  h4 {
    font-size: 16px;
  }
}

.tableEvaluationProgressTh {
  margin: 0px;
}

.pull-middle {
  margin: 0 auto;
}

.editable-field {
  display: flex;
  align-items: center;
  margin-right: 2rem;

  input {
    min-width: 25rem;
  }
}

.scorePoints {
  margin: 0px;
  text-align: left;
  margin-bottom: 40px;
}

.btn-data-sort {
  background-color: transparent;
}

.grow:hover {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}

.average_tool {
  padding: 0 1px;
}

.tableEvaluationProgressBar {
  background-color: white;
  border: 1px solid #c0c0c0;
}

.flex-between-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.score-badge {
  line-height: 20px;
  min-width: 20px;
  height: 20px;
  padding: 0px;
}

.insightNameTable {
  max-width: 150px;
}

.swotManagementValueColumn {
  margin-left: auto;
  margin-right: auto;
  width: 60px;
}

.tableEvaluationSwot {
  padding-top: 0px;
  padding-bottom: 0px;
  max-height: 30px;
}

.textAlignCenter {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.spinner {
  div {
    &::before {
      background-color: $color_black_haze_approx_dark;
    }
  }
}

.file-box {
  float: left;
  width: 224px;

  @media (max-width: 1440px) {
    width: 240px;
    max-width: 240px;
  }

  @media (max-width: 1024px) {
    width: 275px;
    max-width: 275px;
  }

  @media (max-width: 768px) {
    width: 50%;
    max-width: 50%;
  }

  @media (max-width: 425px) {
    max-width: 100%;
    width: 100%;
  }

  .file {
    border: 1px solid #e7eaec;
    padding: 0;
    background-color: #ffffff;
    position: relative;
    margin: 0 10px 10px 10px;

    .icon {
      height: 100px;
      overflow: hidden;
      padding: 15px 10px;
      text-align: center;

      i {
        font-size: 70px;
        color: #dadada;
      }
    }

    .image {
      height: 100px;
      overflow: hidden;
    }

    .file-name {
      padding: 10px;
      background-color: #f8f8f8;
      border-top: 1px solid #e7eaec;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;

      small {
        color: #676a6c;
      }
    }

    .corner {
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      line-height: 0;
      border: 0.6em solid transparent;
      border-right: 0.6em solid #f1f1f1;
      border-bottom: 0.6em solid #f1f1f1;
      right: 0em;
      bottom: 0em;
    }

    .actions {
      position: absolute;
      display: inline-block;
      right: 0em;
      top: 0em;
      height: 56%;
      width: 28px;
      text-align: center;

      .action-btn {
        font-size: 20px;
        text-align: center;
        clear: both;
        width: 25px;
        height: 25px;
        padding: 3px;
        margin: 5px 0;
      }
    }
  }
}

.search-input {
  display: inline-block;
  margin-left: 10px;

  input {
    @extend .form-control;
  }
}

.infoCardStatistics {
  height: 100px;
  background-color: #f3f3f3;
  border-radius: 4px;
  transition: 0.3s;

  &:hover {
    transform: scale(1.1);
  }
}

.elementCenterAlign {
  margin-left: auto;
  margin-right: auto;
}

.completeReportButton {
  margin-left: auto;
  margin-right: auto;
  background-color: $staleBlue;
  border-radius: 20px;
  border: 1px solid black;
}

.carouselWrapper {
  .carousel-control {
    background-image: none;

    .glyphicon-chevron-right {
      color: red;
      right: 20px;
    }

    .glyphicon-chevron-left {
      color: red;
      left: 20px;
    }
  }
}

.breadcrumbActivities {
  a {
    color: grey;
    font-size: 13px;
  }
}

.staleBlue {
  color: $staleBlue;
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-title {
  font-size: 23px;
  line-height: 23px;
  padding-left: 10px;

  i {
    font-size: 17px;
    line-height: 17px;
  }
}

.customTableTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px !important;
  text-align: center;
}

.centralized {
  display: flex;
  justify-content: center;
}

.verticallyCentralized {
  display: flex;
  align-items: center;
}

.activitiesTable {
  max-height: 600px !important;
  padding: 0px !important;
  overflow-y: scroll !important;
}

.hundredPercentWidth {
  width: 100% !important;
}

.newActivityButton {
  width: 70px;
}

.activitiesTable_new {
  min-width: 100px !important;
}

.activitiesTable_activities {
  min-width: 220px !important;
}

.activitiesTable_weight_progress {
  min-width: 90px !important;
}

.activitiesTable_edit {
  min-width: 80px !important;
}

.tableInlineBlock {
  thead,
  tbody {
    display: inline-block;
  }
}

.teamInvolved_cellSize {
  .titleTD {
    background-color: #efefef;
  }
}

.indicators {
  .indicatorsColumn {
    width: 290px;
    padding: 9px;
    white-space: nowrap;
  }

  .indicatorsHeaderColumn {
    height: 70px;

    td {
      border: none !important;
    }
  }
}

.titleTD {
  background-color: #efefef;
}

.simpleTableCustom {
  padding: 0px !important;
  height: 460px;
  padding-top: 0px !important;

  .simpleTableBody {
    display: flex;
    align-items: center;
  }
}

.rowContainer {
  width: 300px;
}

.simpleTableEffort {
  padding: 0px !important;
  height: 400px;
  max-height: 600px;
  padding-top: 0px !important;

  .simpleTableBody {
    display: flex;
    align-items: center;
  }
}

.indicatorStyle {
  height: 640px;
  padding: 0px !important;
}

.teamTableBox {
  height: 450px;
  display: block;
  padding: 0px !important;
}

.membersTitle {
  td {
    border: none !important;
  }
}

.membersTitleTd {
  visibility: hidden;
}

.fixedScroll {
  max-height: 170px;
  overflow-x: auto;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.competitiveReportCard {
  height: 150px;
  max-height: 152px;
}

.privacy-policy-rules {
  max-height: 60vh !important;
  padding: 0 30px 30px 30px;

  div {
    padding: 20px 0 20px 0;
    font-weight: bold;
  }
  span {
    display: block;
    text-align: justify;
    padding: 5px 0 5px 0;
  }
}

.privacy-policy-accept {
  height: 20px;
  margin: 30px 30px 0px 30px;
  font-weight: bold;
}

.previewTableScroll {
  max-height: 250px;
  width: 100%;
  overflow-x: auto;
  padding-top: 0px;
  padding-bottom: 0px;
}

.previewTableCell {
  width: 105px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionsTableCell {
  width: 200px !important;
  white-space: nowrap;

  h5,
  div {
    width: 200px;
    margin: 10px;
  }
}

.marginTop-20 {
  margin-top: 20px;
}

.ownerTableCell {
  width: 200px !important;

  h5,
  div {
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 10px;
  }
}

.ownerTableOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.errorBackground {
  background: #ff9b9b;
}

.concurrenceTableFixedScroll {
  min-width: 255px !important;
  max-height: 350px !important;
}

.SingleTableFixedScroll {
  min-width: 255px !important;
  max-height: 350px !important;
}

.okrKanbanFixedScroll {
  max-height: 300px !important;
}

.monthEvaluations {
  max-height: 298px !important;
}

.teamFixedScroll {
  max-height: 325px !important;
}

.deliveryDateFixedScroll {
  max-height: 300px !important;
}

.progressTableFixedScroll {
  max-height: 495px !important;
}

.effortFixedScroll {
  max-height: 310px !important;
}
.reasonCell {
  max-width: 107px;
}

.reasonCell2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
}
.activitiesVerticalScroll {
  max-height: 400px !important;
}
.activitiesHorizontalScroll {
  max-width: 1100px !important;
}

.newDate {
  margin-top: 10px;
}

.deleteSchedulePlanTD {
  width: 40px;
  cursor: pointer;

  div {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fix-13pxFont {
  font-size: 13px !important;
}
.fix-11pxFont {
  font-size: 11px !important;
}

.deleteScheduleDiv {
  width: 100%;
}
.forecastCell {
  width: 150px;
}

.currentDeadlineCell {
  width: 80px !important;
  cursor: pointer;

  span {
    height: 20px;
  }
}

.activitiesInfo::after {
  content: none;
}

.activitiesInfo::before {
  content: none;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}

.seaGreenDot {
  background-color: #00b050;
}

.salmonDot {
  background-color: #ff7a62;
}

.flowerBlueDot {
  background-color: #499df5;
}

.subHeaderClass {
  display: flex;
  justify-content: space-evenly;
}

.patternFontSize {
  font-size: 12px;
}

.greenbackground {
  background-color: #0c9247;
}

.redbackground {
  background-color: #f54e4e;
}

.yellowbackground {
  background-color: #ffc107;
}

.greenDot {
  height: 12px;
  width: 12px;
  background-color: #0c9247;
  border-radius: 50%;
  display: inline-block;
}

.redDot {
  height: 12px;
  width: 12px;
  background-color: #f54e4e;
  border-radius: 50%;
  display: inline-block;
}

.greyDot {
  height: 12px;
  width: 12px;
  background-color: grey;
  border-radius: 50%;
  display: inline-block;
}

.yearCell {
  width: 100px;
}

.yellowDot {
  height: 12px;
  width: 12px;
  background-color: #ffc107;
  border-radius: 50%;
  display: inline-block;
}

.blueDot {
  height: 12px;
  width: 12px;
  background-color: #7194db;
  border-radius: 50%;
  display: inline-block;
}

.blue2Dot {
  height: 12px;
  width: 12px;
  background-color: #4285f4;
  border-radius: 50%;
  display: inline-block;
}

.baselineDot {
  height: 12px;
  width: 12px;
  background-color: #b8cfe7;
  border-radius: 50%;
  display: inline-block;
}

.deadlineDot {
  height: 12px;
  width: 12px;
  background-color: #668fba;
  border-radius: 50%;
  display: inline-block;
}

.physicalAdvanceDot {
  height: 12px;
  width: 12px;
  background-color: #499df5;
  border-radius: 50%;
  display: inline-block;
}

.reportMapHeader {
  background-color: #d7d7d7;
  padding: 12px;
}

.firstBox label {
  white-space: nowrap;
}

.reportMapSecondHeader {
  background-color: #efefef;
  padding: 5px;
}

.fix-80 {
  width: 80px !important;
}

.fix-70 {
  width: 70px !important;
}

.fix-60 {
  width: 60px !important;
}

.fix-50 {
  width: 50px !important;
}

.fix-20 {
  width: 20px !important;
}

.fix-40 {
  width: 40px !important;
}

.fix-110 {
  width: 110px !important;
}

.fix-120 {
  width: 120px !important;
}
.fix-250 {
  width: 250px !important;
}

.fix-150 {
  width: 150px !important;
}

.reportBodyCell td {
  padding: 20px;
  max-height: 400px !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.projectNameStyle {
  display: flex;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}

.projectNameTd {
  max-height: 55px;
}
.height40 {
  height: 40px;
}

.nameEllipsis {
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.scheduleEllipsis {
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.kanbanNameEllipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.marginLeft-10 {
  margin-left: 10px;
}

.reportMapfixedScroll {
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
}

.executiveReportTablesContainer {
  display: flex;
}
.legendBox {
  display: flex;
  margin-bottom: 10px;

  div {
    padding-right: 20px;
  }
}

.openChart {
  visibility: hidden;
  position: absolute;
  left: 10;
  top: 10;
}

.editButtonTableProject {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  color: #555555;
}

.editButtonTableProject:hover {
  background-color: #6b42a9;
  color: #fff;
  cursor: pointer;
}

.deleteButtonTableProject {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  color: red;
}

.MuiFormControlLabel-label {
  font-size: 14px !important;
}

.MuiFormHelperText-root {
  font-size: 1.5rem !important;
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
}

.deleteButtonTableProject:hover {
  background-color: #6b42a9;
  color: #fff;
  cursor: pointer;
}

.descriptionBoxBody {
  height: 450px;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.zeroMargin {
  margin: 0px;
}

.conclusionDateStyle {
  .modal-content {
    width: 400px;
  }
}

.progressTableBody {
  height: 640px;
  padding: 0px !important;
}

.formattedTD {
  .MuiFormControl-marginNormal {
    margin: 0px !important;
  }
}

.speech-bubble {
  width: 65px;
  height: 35px;
  text-align: center;
  background-color: transparent;
  position: relative;
  font-size: 24px;
}

.block-ui {
  &.blocked {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: #000000;
    overflow: hidden;
    opacity: 0.5;
    z-index: 999999999;
    transition: ease-in-out 200ms;
  }

  &.unblocked {
    transition: ease-in-out 200ms;
  }

  .loader {
    background-color: transparent;
    height: 11rem;
    color: #fff;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .label {
      display: block;
      padding-top: 2.4rem;
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 1.5rem;
    }
  }
}

.google-chart {
  position: relative;
  height: 90px;
  width: 90px;
  display: block;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.transparentClass {
  background-color: transparent;
  color: black;
}

.fontSize20 {
  font-size: 20px;
}

.fontSize10 {
  font-size: 10px;
}

.entityContainer {
  margin-left: 35px;
  cursor: default;
}

.entityBreadcrumb {
  margin-top: 3px;
  max-width: 140px;
}

.fontSize12 {
  font-size: 12px;
}

.insightMainBreadcrumb {
  h5 {
    color: black;
  }
}
.markAsRead {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.markAsRead:hover {
  background-color: whitesmoke;
}

.filteredBackground {
  background-color: #eeeeee;
  border-left: 4px solid rgb(107, 66, 169) !important;
}

.paddingLeft18 {
  padding-left: 18px;
}

.paddingLeft0 {
  padding-left: 0;
}

#nprogress {
  position: fixed;
  left: 0;
  top: 0;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  transition: 0.5s;
  z-index: 9000000;
}

#nprogress .bar {
  background: #ecfd82 !important;
}

#nprogress .spinner-icon {
  display: none;
}

.nprogress-custom-parent {
  overflow: none !important;
  position: relative;
}

.patternPurpleButton {
  background-color: #6b42a9 !important;
  border-radius: 5px;
  color: white !important;
}

.patternPurpleBorderColor {
  border-color: #6b42a9 !important;
}

.paddingBottom15 {
  padding-bottom: 15px;
}

.marginMinusLeft15 {
  margin-left: -15px;
}

.header-insight-dialog {
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb-insight-view ol {
  display: flex;
  flex-wrap: nowrap;

  li {
    background-color: transparent !important;
    color: #fff;

    h5 {
      white-space: nowrap;
    }
  }
}

.paddingLeft0 {
  padding-left: 0px;
}

.customTableTeste {
  max-height: 300px !important;
}

.customGenericRow td {
  padding: 0px !important;
}

.customGenericRow th {
  padding: 0px !important;
}

.widthController {
  width: 900px !important;
}

.leadersModal {
  width: 1000px !important;
  height: 500px !important;
}

@media screen and (max-width: 990px) {
  .box-card-goal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.descriptionEllipsis {
  word-break: break-all;
}

.brazil-flag-background {
  background-image: url("../images/brazil-flag.png") !important;
}

.en-flag-background {
  background-image: url("../images/united-states-flag.png");
}

.es-flag-background {
  background-image: url("../images/spain-flag.png");
}

.flag-background {
  margin-top: 2px;
  height: 100%;
  width: 20px;
  background-repeat: no-repeat;
}

.reportMapDotStyles {
  display: flex;
  align-items: center;
  width: 100px;
  justify-content: space-evenly;
}

.reportMapDotContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.measurementRequires {
  margin-top: 15px;
  width: 500px;
  background: #fcf8e3 !important;
  padding: 20px;
  border-radius: 6px;
}

.cpAnalysisRequires {
  margin-top: 5px;
  width: 700px;
  background: #fcf8e3 !important;
  padding: 20px;
  border-radius: 6px;
}

.conclusionModalWarning {
  width: 360px !important;
}

.reportMapProjectName {
  width: 100px !important;
}

.reportMapPatternHeader {
  min-width: 185px !important;
  max-width: 185px !important;
}

.reportMapSchedule {
  width: 230px !important;
}

.reportMapSubHeader {
  background-color: #efefef;
}

.singleTeste {
  width: 100% !important;
  max-width: 1200px !important;
}

.alternatePatternCell {
  height: 100px !important;
  width: 40px !important;
  padding: 0px !important;
  background-color: #efefef !important;
}

.testin {
  width: 1090px !important;
}

.divChartSubHeader {
  width: 170px;
}

.noPadding {
  padding: 0px !important;
}

.chartDiv {
  padding: 0px !important;
}

.chartDiv:hover .openChart {
  visibility: visible;
}

.patternTableCell {
  height: 100px !important;
  padding: 0px !important;
  background-color: white !important;
}

.reportTableDiv {
  height: 100%;

  table {
    width: 100%;
    height: 100%;

    tr {
      background-color: white !important;
    }
  }
}

.projectNameCell {
  width: 150px !important;
}

.zoomModalDiv {
  width: 600px;
  height: 300px;
}

.padding10 {
  padding: 7px !important;
}

.effortType {
  div {
    margin-bottom: 0px !important;
  }
}
.effortTypeTD {
  padding-top: 0px !important;
}
.ownerModule {
  display: flex !important;
  align-items: center;
  color: white;
  white-space: nowrap;
  cursor: pointer;

  p {
    margin: 0;
    padding-left: 10px;
  }
}

.width-400 {
  div {
    width: 400px !important;
  }
}

.ownerModule:hover {
  color: #ddec7a;
}

.selected-user-role {
  color: white;
  background: #6b42a9;
}

.selected-user-role:focus {
  color: white;
  background: #6b42a9;
}

.profileTab {
  height: 480px;
}

.fullHeight {
  height: 100%;
}

.horizontalProgress {
  display: flex;
}

.domainContainer {
  height: 100%;
}

.invalidDomainContainer {
  width: 500px;
  height: 500px;
  border: 1px solid black;
}

.userTypeInCompany {
  width: 90px !important;
  white-space: nowrap;

  h5,
  div {
    width: 90px;
    margin: 10px;
  }
}

.domainTableTitle {
  span {
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  hr {
    margin: 0px;
    padding: 0px;
  }

  div {
    width: 170px;
  }

  width: 100%;
  display: flex;
  justify-content: space-between;
}

.genericTableTitle {
  span {
    font-size: 20px;
  }

  hr {
    margin: 0px;
    padding: 0px;
  }

  width: 100%;
  display: flex;
  justify-content: center;
}

.tooltip_messages2 {
  z-index: 999999;
}

.indexZZ {
  z-index: 9;
}

.lockdownHeaderLi {
  width: 50px;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;

  div {
    font-size: 20px;
    color: white;
    cursor: pointer;
  }
}

.languageHeaderLi {
  width: 80px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notificationsHeaderLi {
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bellContainer {
  top: 2px;
  font-size: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statusQuo {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.notificationCount {
  content: counter(my-awesome-counter);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  --size: 18px;
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 7px;
  right: 14px;
  background: #6b42a9;
  border-radius: 50%;
  text-align: center;
  box-shadow: 1px 1px 0 #999;
}
.statusQuoCol {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 40px;
}

.physicalProgressCol {
  margin-right: 10px;
}

.cardLowLine {
  display: flex;
  padding-top: 10px;
  flex-wrap: "wrap";
}

.cursorPointer {
  cursor: pointer;
}

.progressProject2 {
  display: flex;
  align-items: center;
  height: 70px !important;
  width: 100% !important;
  margin: 0 !important;
  position: relative;
}

.physicalColor {
  background-color: #9dcef0;
}

.insideDivProgress {
  font-size: 15px;
  width: 40px;
  position: absolute;
  margin-left: 20px;
}

.zeroPadding {
  padding: 0px !important;
}

.physicalProgressCol2 {
  display: flex;
  align-items: center;
  width: 100%;
}

.advanceColumnConfig {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.executiveReportLines {
  border-bottom: 2px solid #d7d7d7;
  height: 72px !important;
  max-height: 72px !important;
}

.alignItemsCenter {
  align-items: center;
}

.maxHeight40 {
  max-height: 38px;
}

.greyBackground {
  background-color: #efefef;
}

.notifications-ul {
  width: 500px;
  max-height: 500px;
  padding: 5px;
  z-index: 9999999999999;
}

.notification-li {
  color: black;
  padding: 10px;
  border-radius: 5px;
}

.not-li-container {
  display: flex;
}

.notification-description {
  width: 100%;
  height: 100%;
  margin-top: 5px;

  strong {
    font-weight: 450;
  }

  cursor: default;
}

.not-li-container:hover {
  background-color: whitesmoke;
}
.notification-side-options {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 80px;
  margin-top: 5px;
}

.notifications-li-title {
  color: black;
  padding: 10px;
  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.justifyEnd {
  justify-content: flex-end;
}
.notification-type-label {
  justify-content: flex-end;
}

.chipchip {
  font-size: 20px;
}

.notificationsViewMore {
  display: flex;
  justify-content: flex-end;
}

.relativeNotTime {
  font-size: 15px;
  margin-bottom: 5px;
}

.main-footer {
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  z-index: 50;
  transition: 0s;
  height: 30px;
  line-height: 30px;
  padding: 0px 15px;
}

.only-print {
  display: none;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    overflow-x: hidden;
  }

  .only-print {
    display: block;
  }

  .print-hide {
    display: none;
    visibility: hidden;
  }

  #jsd-widget {
    display: none !important;
  }

  .tool-title-pdf {
    display: block;
  }

  .name-tab-pdf {
    display: block;
  }

  .nav-tabs {
    display: none !important;
  }

  .bar {
    display: block !important;
  }

  .levelBarRed {
    background-color: #f54e4e !important;
    -webkit-print-color-adjust: exact;
  }

  .levelBarYellow {
    background-color: #f3ce37 !important;
    -webkit-print-color-adjust: exact;
  }

  .levelBarGreen {
    background-color: #15ad15 !important;
    -webkit-print-color-adjust: exact;
  }

  .levelBarBlue {
    background-color: #1a76d2 !important;
    -webkit-print-color-adjust: exact;
  }

  .pointer-level {
    width: 2px;
    height: 25px;
    top: -5px;
    position: absolute;
    background-color: #000 !important;
    -webkit-print-color-adjust: exact;
  }

  .greenDot {
    background-color: #0c9247 !important;
    -webkit-print-color-adjust: exact;
  }

  .redDot {
    background-color: #f54e4e !important;
    -webkit-print-color-adjust: exact;
  }

  .greyDot {
    background-color: grey !important;
    -webkit-print-color-adjust: exact;
  }

  .yellowDot {
    background-color: #ffc107 !important;
    -webkit-print-color-adjust: exact;
  }

  .baselineDot {
    -webkit-print-color-adjust: exact;
    background-color: #b8cfe7 !important;
  }

  .deadlineDot {
    -webkit-print-color-adjust: exact;
    background-color: #668fba !important;
  }

  .physicalAdvanceDot {
    -webkit-print-color-adjust: exact;
    background-color: #499df5 !important;
  }

  .card-drag-item {
    -webkit-print-color-adjust: exact;
    background-color: #eee !important;
  }

  .card-header {
    background-color: #f1f1f1 !important;
  }

  .simple-card-table .card-value .label-bottom {
    background-color: #eee !important;
  }

  .nine-box-evaluation .cards .card .label {
    background-color: #eee !important;
  }

  .nine-box-evaluation .cards .card .label-bottom {
    background-color: #eee !important;
  }

  .simple-average-evaluation .average-div {
    background-color: #f1f1f1 !important;
  }

  .filter-field .card-value {
    background-color: #f1f1f1 !important;
  }

  .simple-lines-table .simple-line .right-label {
    background-color: #999 !important;
  }

  .simple-lines-table .simple-line {
    background-color: #f1f1f1 !important;
  }

  .table-evaluation {
    background-color: #f6f6f6 !important;

    th {
      background-color: #f1f1f1 !important;
    }
  }

  .card-container .card-body {
    background-color: #f6f6f6 !important;
  }

  .tool-title {
    padding: 0px;
    margin: 0px;
    margin-top: 250px !important;
  }

  .main-footer {
    position: relative;
  }

  .simple-table {
    font-size: 8px !important;
  }
}
.activityOptions {
  width: 5px;
  cursor: pointer;
  span {
    padding: 0;
  }
}

.mentionOption {
  cursor: pointer;
  z-index: 9999;
  position: relative;
}

.mentionOption:focus {
  color: red;
  background-color: #56348a !important;
}

.timelineComment {
  height: 100px;
}

.projectResponsibleSelect {
  padding-left: 0 !important;
  padding-right: 30px !important;
}

.mentioned-suggestion {
  padding: 10px;

  div {
    padding: 2px;
  }
}
.margin20 {
  margin-top: 20px;
  margin-bottom: 70px;
}

.singleTable {
  justify-content: space-between;
  cursor: pointer;
}

.overallTable {
  justify-content: space-between;
  cursor: pointer;
}

.singleTable-title-wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 0px;
  align-items: center;
  flex: 1;
}

.singleTable-AverageBadge {
  width: 158px;
  display: flex;
  justify-content: flex-end;

  div {
    width: 80px;
    margin-right: 15px;
    font-weight: 3000;
  }
}

.singleTable-body {
  padding: 0px;

  .header {
    display: flex;
    font-size: 12px;
    text-align: center;
    background-color: #eee;
    padding: 4px 0px;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;

    .first-header-cell {
      width: auto;
      flex: 1;
    }
    .header-cell {
      width: 100px;
      padding: 0px 10px;
      overflow: hidden;
    }
    .last-header-cell {
      width: 150px;
    }
  }
}

.overallTable-body {
  padding: 0px;

  .header {
    display: flex;
    font-size: 12px;
    text-align: center;
    background-color: #eee;
    padding: 4px 0px;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;

    .overallTable-first-cell {
      width: auto;
      flex: 1;
    }
    .overallTable-header-cell {
      width: 150px;
      padding: 0px 10px;
      overflow: hidden;
    }
  }
}

.pestalLineTitle {
  width: auto;
  flex: 1;
  padding: 15px 8px;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  cursor: pointer;
}

.pestalScore {
  width: 100px;
  padding: 0px 10px;
}

.pestalTotalScore {
  width: 70px;
  padding: 0px 10px;
  text-align: center;
}

.pestalProgressWrapper {
  width: 70px;
  display: flex;
  justify-content: center;
}

.pestalProgressBar {
  width: 50px;
  height: 20px;
  text-align: center;
  background-color: white;
  border: 1px solid #c0c0c0;
  margin-left: 0;
  padding-left: 0;
}
.emptyGrey {
  background-color: #c0c0c0;
}

.concurrenceDarkRed {
  background-color: hsl(0, 85%, 55%);
}

.concurrenceLightRed {
  background-color: hsl(0, 80%, 66%);
}

.concurrenceYellow {
  background-color: hsl(59, 79%, 57%);
}

.concurrenceOrange {
  background-color: hsl(44, 79%, 58%);
}
.concurrenceLightGreen {
  background-color: hsl(95, 53%, 58%);
}

.concurrenceDarkGreen {
  background-color: hsl(138, 53%, 50%);
}

.comp-first-header-cell {
  width: 5%;
}
.comp-logo-header-cell {
  width: 5%;
}
.comp-number-header-cell {
  width: 5%;
  padding: 10px 0;
}
.comp-header-cell {
  width: 10%;
}
.comp-long-header-cell {
  width: 10%;
  padding: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comp-evaluation-header-cell {
  width: 5%;
  padding: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comp-evaluation-cell-rotation {
  transform: rotate(300deg);
}

.concurrenceManagementTable {
  cursor: pointer;
}

.concurrenceTable-title-wrapper {
  width: 50%;
}

.concurrenceTable-body {
  padding: 0px;

  .header {
    display: flex;
    font-size: 12px;
    text-align: center;
    background-color: #eee;
    padding: 8px 0px;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    height: 90px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      word-break: all;
    }
  }
}

.flexCenterAligned {
  display: flex;
  justify-content: center;
  align-items: center;
}

.concurrenceTableLine {
  display: flex;
  align-items: center;

  div {
    text-align: center;
    font-size: 12px;
  }

  border-bottom: 1px solid #e2e0e0;
  cursor: pointer;

  .comp-evaluation-header-cell {
    height: 60px;
    border-left: 1px solid white;
  }
}

.concurrenceTableLine:hover {
  background-color: #eeeeee;
}

.specialization-title {
  display: flex;

  span {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
}

.specializations-view {
  border: 1px solid #ccc;
  margin: 10px 0px;
}

.pestalTabViewDiv {
  justify-content: space-between;
  align-items: center;
}

iframe[name="JSD widget"] {
  min-width: 138px;
  left: 45px;
}

.project-configuration-card-header {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-configuration-card-content {
  .container-projects {
    display: flex;
    width: 100%;
    padding: 0px !important;
  }

  .container-title-projects {
    width: 90%;
    cursor: pointer;
  }

  .container-trash-projects {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.description-project-stage {
  width: 100%;
}

.formController {
  width: 100%;
  margin-top: 10px !important;

  div {
    height: 25px;
    font-size: 16px;
  }

  label {
    font-size: 16px;
  }
}

.previewTableWrapper {
  text-align: center;
  margin-top: 35px;
}
.validationTableWrapper {
  margin-top: 35px;
}

.providerSendButton {
  height: 40px !important;
  margin-top: 16px !important;
  margin-bottom: 8px !important;
  margin-left: 30px !important;
}

.adfsProviderContainer {
  display: flex;
  align-items: center;
}

#chat-widget {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: inline-block;
  .support-chat-icon {
    position: relative;
    margin: 0 auto;
    right: 0;
    &:focus {
      outline: none;
    }
  }
  .powered-by,
  .login-options {
    display: none !important;
  }
}

.chat-class {
  left: 0 !important;
  .top-header,
  .new-conversation {
    background-color: #603a99 !important;
  }
}

.chat-message {
  &.my {
    background-color: #603a99 !important;
  }
}

.chat-conversation {
  &:hover {
    border: 1px solid #603a99 !important;
  }
}

.chat-icon-class {
  background-color: #603a99 !important;
}

.sub {
  position: relative;
}
.itmHolder {
  position: relative;
}

.itmHolder :nth-child(2),
.itmHolder :nth-child(3) {
  position: absolute;
  top: 0;
  width: 100%;
}
.og {
  position: relative;
  text-align: left;
}
#itmHolder {
  position: absolute;
}

.forgot-password-highlight {
  font-size: larger;
  color: #000;
  background-color: $color_black_haze_approx;
}
