$white: #fff;
$white_dark: darken($white, 5%);
$color_astral_approx: #222;
$color_astral_approx_light: lighten($color_astral_approx, 5%);
$color_astral_approx_lighter: lighten($color_astral_approx, 90%);
$color_astral_approx_dark: darken($color_astral_approx, 5%);
$black_10: rgba(0, 0, 0, 0.1);
$color_black_haze_approx: #d4ff62;
$color_black_haze_approx_light: lighten($color_black_haze_approx, 5%);
$color_black_haze_approx_dark: darken($color_black_haze_approx, 30%);
$color_limed_spruce_approx: #374850;
$color_storm_dust_approx: #666;
$color_storm_dust_approx_light: lighten($color_storm_dust_approx, 20%);
$color_storm_dust_approx_lighter: lighten($color_storm_dust_approx, 80%);
$brand_danger_color: #d73925;
$brand_info_color: #31708f;
$brand_success_color: #3c763d;
$brand_warning_color: #8a6d3b;
$brand_danger_bg: #f2dede;
$brand_info_bg: #d9edf7;
$brand_success_bg: #dff0d8;
$brand_warning_bg: #fcf8e3;
$purple: #605ca8;
$staleBlue: #6b42a9;
$darkStateBlue: darken($staleBlue, 5%);
$staleBlueConstrast: #fff;
$icon-font-path: "../../fonts/bootstrap/";