.container-activities {
  width: 100%;

  .simple-table {
    position: relative;
    width: 100%;
    border-color: #dddddd !important;

    thead {
      tr:hover {
        background-color: transparent !important;
      }
    }

    .alert-without-activities {
      color: #818182;
    }

    .project-name {
      font-weight: 400;

      &:hover {
        font-weight: 500;
      }
    }

    .td-date {
      width: 130px;
    }

    .td-plan {
      width: 100px;
    }

    .thead-description {
      background-color: #d7d7d7;

      td {
        font-weight: 600;
      }
    }

    .tbody-activities {
      td {
        font-weight: 500;
        height: 45px;
        font-size: 14px;
      }
    }

    .box-avatar-activitie {
      display: flex;
      align-items: center;
    }

    .td-progress {
      width: 265px;
    }
    .box-bar-progress {
      display: flex;

      span {
        margin-right: 5px;
      }

      .bar {
        width: 100%;
        background-color: #d3d3d3 !important;
        border-radius: 6px !important;
        height: 20px;

        .progress {
          height: 20px;
          background-color: #9dcef0 !important;
        }
      }
    }

    .thead-project {
      background-color: #efefef;

      td {
        font-size: 13px;
        height: 20px;
      }
    }

    .box-project-name {
      display: flex;
      align-items: center;

      div i {
        margin-right: 5px;
        font-size: 16px;
        cursor: pointer;
        padding: 5px;
        width: 25px;
        border-radius: 50%;
      }

      div i:hover {
        background-color: #ccc;
      }

      .project-avatar {
        width: 25px !important;
        height: 25px !important;
        margin-right: 5px;
      }
    }
  }
}

.thead-first {
  background-color: #83899e !important;
  color: #fff !important;
}
