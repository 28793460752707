/** skin-strategyplace.scss */

//grid variables
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-horizontal: 1rem;

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@import "variables.scss";
@import "pre-bootstrap-custom.scss";

$color_astral_approx: #333;
$color_astral_approx_lighter: #fff;
$color_astral_approx_dark: #111;

.sidebar-collapse {
  .sidebar-menu {
    li > a {
      overflow: visible !important;
      border-left: none !important;

      .icon {
        position: relative;
        z-index: 50;
      }

      .sidebar-arrow {
        right: 7px;
      }
    }

    .selectedTip {
      right: 1px !important;
    }
  }
}

body {
  font-family: "Montserrat";
}

.nav {
  .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
    border: transparent;
  }

  li {
    .btn {
      &:focus {
        outline: none;
      }
    }

    a {
      cursor: pointer;
      color: $color_astral_approx;
    }
  }
}

.skin-strategyplace {
  .bg-secondary {
    background-color: #f4ffba;
  }

  .bg-primary-dark {
    background-color: $color_black_haze_approx_dark;
  }

  .box {
    margin: 20px 0 40px 0;

    &.box-primary {
      border: 2px solid #eee;
      border-top: 3px solid #ddd;
      border-radius: 4px;

      .box-header {
        background-color: #f8f8f8;
        border-bottom: 2px solid #ccc;

        i {
          color: $staleBlue;
        }

        .box-title {
          color: $staleBlue;
        }

        small {
          color: #666;
        }
      }
    }
  }

  .form-control {
    &:focus {
      border-color: $color_black_haze_approx_dark;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(231, 255, 109, 0.6);
    }
  }

  .img-logo {
    width: 153px;
  }

  .alert {
    &.alert-danger {
      color: $brand_danger_color !important;
    }

    &.alert-info {
      color: $brand_info_color !important;
    }

    &.alert-success {
      color: $brand_success_color !important;
    }

    &.alert-warning {
      color: $brand_warning_color !important;
    }
  }

  .panel {
    background-color: $white;
    margin: 0;
  }

  .login-box {
    margin: auto;

    .login-box-body {
      background-color: $white;
    }
  }

  .profile-user-img {
    border-color: $color_black_haze_approx_dark;
    width: auto;
  }

  .profile-user-img-100 {
    width: 100px;
  }

  .profile-user-img-25 {
    width: 25px;
  }

  .dropdown-menu {
    li {
      a {
        cursor: pointer;
      }
    }
  }

  .nav-tabs-custom {
    > .nav-tabs {
      > li {
        &.active {
          border-color: #eee;
          border-bottom: 3px solid #ddd;

          a {
            background: #eee;
            border-left-color: $white_dark;
            border-right-color: $white_dark;

            &:hover {
              border-left-color: $white_dark;
              border-right-color: $white_dark;
            }
          }
        }
      }
    }
  }

  .navbar-bread-crumbs {
    display: block;
    float: left;
    color: $white;
    font-weight: 400;
    padding-top: 15px;

    a {
      color: $white;
    }
  }

  .register-box {
    margin: auto;

    .register-box-body {
      background-color: $white;
    }
  }

  .main-header {
    max-height: 15.3rem;
    background-color: $color_astral_approx;

    .navbar {
      background-color: #222;
      margin-left: 0;

      .nav {
        > li > a {
          color: $white;
          padding: 15px 14px;

          &:hover {
            background: $black_10;
            color: $color_black_haze_approx;
          }

          &:active {
            background: $black_10;
            color: $color_black_haze_approx;
          }

          &:focus {
            background: $black_10;
            color: $color_black_haze_approx;
          }
        }

        .open > a {
          background: $black_10;
          color: $color_black_haze_approx;

          &:hover {
            background: $black_10;
            color: $color_black_haze_approx;
          }

          &:focus {
            background: $black_10;
            color: $color_black_haze_approx;
          }
        }

        > .active > a {
          background: $black_10;
          color: $color_black_haze_approx;
        }
      }

      .sidebar-toggle {
        color: $white;

        &:hover {
          color: $color_black_haze_approx;
          background: $black_10;
          background-color: $color_astral_approx;
        }

        &::before {
          content: "";
        }
      }
    }

    .logos {
      padding: 0;
      width: 224px;
      margin: 5px 0 5px 4px;
      height: 41px;
      line-height: 35px;
      letter-spacing: 2px;
      transition: width 0.3s ease-in-out;
      display: block;
      float: left;
      font-size: 16px;
      text-align: center;
      font-family: "Montserrat";
      font-weight: 300;
      overflow: hidden;
    }

    .logo-logged {
      width: 188px;
      transition: 0.3s;

      &:hover {
        transform: scale(1.03);
      }
    }

    .user-menu > .dropdown-menu {
      padding: 0;
    }

    li.user-header {
      background-color: $color_astral_approx_dark;
    }

    .user-menu > .dropdown-menu {
      padding: 0;
    }

    li.user-header {
      background-color: $color_astral_approx_dark;
    }
  }

  .content-wrapper {
    padding-bottom: 50px;
    .content {
      display: block;
      overflow: auto;
    }

    background-color: white;

    .profile_image_box {
      &.inactive {
        opacity: 0.25;
      }

      .form-group {
        border-radius: 6px;
        box-shadow: 0px 0px 25px -2px rgba(0, 0, 0, 0.3);
        width: 98%;
        margin: 0 auto 20px;
        min-height: 334px;

        input[type="radio"] {
          display: none;
        }
      }

      canvas {
        display: block;
        background: url("../images/bg_dragndrop.jpg") no-repeat center center;
        border-radius: 50%;
        padding: 3px;
        border: 3px solid $color_black_haze_approx_dark;
      }
    }
  }

  .content-header {
    background: transparent;
    padding: 20px 45px;
  }

  .wrapper {
    background-color: $color_astral_approx;
  }

  .main-sidebar {
    overflow: hidden;
    background-color: $color_astral_approx;
  }

  .left-side {
    background-color: $color_astral_approx;
  }

  .control-sidebar-dark {
    background-color: $color_astral_approx;

    .control-sidebar-menu li a {
      &:hover {
        background-color: $color_astral_approx_light;
      }
    }
  }

  .control-sidebar-dark .nav-tabs.control-sidebar-tabs > {
    li {
      > a {
        background-color: $color_astral_approx_dark;
        border: $color_astral_approx_dark;

        &:focus {
          border-left-color: $color_astral_approx_dark;
          border-bottom-color: $color_astral_approx_dark;
        }
      }

      &.active > a {
        background: $color_astral_approx;

        &:hover {
          background: $color_astral_approx_light;
        }

        &:focus {
          background: $color_astral_approx;
        }

        &:active {
          background: $color_astral_approx;
        }
      }
    }
  }

  .sidebar-form {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
    border: 1px solid $color_limed_spruce_approx;
    margin: 10px;

    input[type="text"] {
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: none;
      background-color: $color_limed_spruce_approx;
      border: 1px solid transparent;
      height: 35px;
      color: $color_storm_dust_approx; //Instead of the line below you could use @include border-top-left-radius($radius)
      border-top-left-radius: 2px; //Instead of the line below you could use @include border-top-right-radius($radius)
      border-top-right-radius: 0; //Instead of the line below you could use @include border-bottom-right-radius($radius)
      border-bottom-right-radius: 0; //Instead of the line below you could use @include border-bottom-left-radius($radius)
      border-bottom-left-radius: 2px;

      &:focus {
        background-color: $white;
        color: $color_storm_dust_approx;

        + .input-group-btn .btn {
          background-color: $white;
          color: $color_storm_dust_approx;
          border-left-color: $white;
        }
      }
    }

    .btn {
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: none;
      background-color: $color_limed_spruce_approx;
      border: 1px solid transparent;
      height: 35px;
      color: $color_astral_approx_lighter; //Instead of the line below you could use @include border-top-left-radius($radius)
      border-top-left-radius: 0; //Instead of the line below you could use @include border-top-right-radius($radius)
      border-top-right-radius: 2px; //Instead of the line below you could use @include border-bottom-right-radius($radius)
      border-bottom-right-radius: 2px; //Instead of the line below you could use @include border-bottom-left-radius($radius)
      border-bottom-left-radius: 0;
    }
  }

  .user-panel > .info {
    color: $white;

    > a {
      color: $white;
    }
  }

  .sidebar-menu > li {
    position: relative;
    font-size: 16px;

    &.childRelated,
    &.childRelated:hover a {
      background-color: #444;
      border-color: #444;
    }

    &.isSelectedParent,
    &.isSelectedParent:hover a {
      background-color: #222 !important;
      border-color: #222;
    }

    > .selectedTip {
      position: absolute;
      top: 20px;
      right: 5px;
      background-color: #222;
      width: 25px;
      z-index: 10;
      height: 25px;
      transform: rotate(45deg);
    }

    > a {
      border-left: 5px solid transparent;
      cursor: pointer;
      overflow-x: hidden;
      text-overflow: ellipsis;
      padding-right: 25px;
      padding-left: 10px;
    }

    .icon {
      width: 20px;
    }

    .sidebar-arrow {
      position: absolute;
      top: 0;
      right: 11.5px;
      height: 43px;
      line-height: 43px;
      font-size: 12px;
    }

    ul > li {
      padding: 8px;

      a {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &.header {
      &.dark {
        padding-top: 15px;
        background: $color_astral_approx;
      }

      color: $white;
      background: $color_astral_approx_light;
      padding: 2px 0px 2px 5px;
      min-height: 26px;

      .span-85 {
        width: 85%;
      }

      .span-15 {
        width: 15%;
      }

      .span-70 {
        width: 70%;
      }

      a {
        color: $color_astral_approx_dark;

        &:hover {
          color: $color_astral_approx_dark;
        }

        .fa-inverse {
          color: $color_black_haze_approx;
        }
      }
    }

    &:hover > a {
      color: $color_black_haze_approx;
      background: $color_astral_approx;
      border-left-color: $color_astral_approx;

      span {
        color: $color_black_haze_approx;
      }
    }

    &.active {
      border-bottom: 3px solid $staleBlue;
    }

    &.active > a {
      color: $staleBlueConstrast;
      background: $staleBlue !important;
    }

    &.active:hover > a {
      span {
        color: #fff;
      }

      border-color: transparent;
    }

    > .treeview-menu {
      margin: 0 1px;
      background: $color_astral_approx_light;
    }
  }

  .sidebar {
    display: flex;
    flex-direction: column;
  }

  .sidebar a {
    color: $color_astral_approx_lighter;

    &:hover {
      text-decoration: none;
    }
  }

  .treeview-menu {
    background-color: #444 !important;
    padding: 0px;

    &.open {
      display: block;
    }

    > li {
      padding: 5px 8px 5px 4px !important;
      border-left: 4px solid transparent;

      > a {
        cursor: pointer;
        color: $color_astral_approx_lighter;
        font-size: 16px !important;

        &:hover {
          color: $color_black_haze_approx;

          span {
            color: $color_black_haze_approx;
          }
        }
      }

      &.active > a {
        color: $staleBlueConstrast;

        span {
          color: $staleBlueConstrast;
        }
      }

      &.active {
        transition: 0.3s;
        border-left: 4px solid $staleBlueConstrast;
      }
    }
  }

  &.layout-top-nav .main-header > .logo {
    background-color: $color_astral_approx;
    color: $white;
    border-bottom: 0 solid transparent;

    &:hover {
      background-color: $color_astral_approx;
    }
  }

  .inputFile {
    display: none;
  }

  .labelInputFile {
    box-shadow: none;
    border-width: 1px;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    background-color: $btn-primary-bg;
    border: $btn-primary-border;
    color: $btn-primary-color;

    &:hover {
      background-color: $brand-darker;
    }

    cursor: pointer;
    margin: 10px;
    padding: 6px 20px;
  }

  .min-height-200 {
    min-height: 200px;
  }

  .min-height-250 {
    min-height: 250px;
  }

  .min-height-300 {
    min-height: 300px;
  }
}

@media (max-width: 767px) {
  .skin-strategyplace {
    .navbar-bread-crumbs {
      padding-left: 0;
      width: 100%;
      text-align: center;
      padding-top: 0px;

      a {
        width: 10px;
        max-width: 10px;
        height: 10px;
        max-height: 10px;
        position: relative;
        overflow: hidden;
      }
    }

    .content-wrapper {
      padding-top: 145px;
    }

    .content-wrapper-loggedoff {
      padding-top: 50px;
    }

    .main-sidebar {
      padding-top: 150px;
    }

    .main-header {
      max-height: 150px;

      .logos {
        width: 180px;
      }

      .logo {
        float: right;
        margin: 5px 7px 5px 0;
        width: 97%;
      }

      .logo-logged {
        width: 81%;
      }

      .navbar .dropdown-menu li {
        &.divider {
          background-color: $black_10;
        }

        a {
          color: $color_astral_approx_dark;

          &:hover {
            background: $color_astral_approx;
          }
        }
      }

      .navbar-custom-menu {
        max-height: 50px;
      }
    }

    .main-footer {
      font-size: 1.1rem;
    }
  }
}

@media (min-width: 768px) {
  .sidebar-mini {
    &.sidebar-collapse {
      .main-header {
        .navbar {
          .logo {
            display: none;
          }

          margin-left: 3px;
        }
      }
    }
  }

  .navbar-bread-crumbs {
    //width: 206px;
    width: auto;
    padding-top: 0px;
    padding-left: 10px;
    text-align: center;
  }

  .navbar-custom-menu {
    max-height: 50px;
  }

  .navbar-nav > li > a {
    height: 50px;
    padding-bottom: 10px !important;
    cursor: pointer;
  }
}

@media (min-width: 1024px) {
  .navbar-bread-crumbs {
    //width: 462px;
    //max-width: 462px;
    width: auto;
    text-align: left;
    padding-top: 0px;
    padding-left: 10px;
  }
}

@media (min-width: 1440px) {
  .navbar-bread-crumbs {
    //width: 800px;
    //max-width: 800px;
    width: auto;
    text-align: left;
    padding-top: 15px;
    padding-left: 10px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.panel {
  .panel-body {
    padding: 2px;
  }
}

.pop-over-list {
  margin: 10px 0 0 0;
  list-style: none;
  padding: 0px;

  li {
    &.divide {
      border-top: 1px solid $color_black_haze_approx;
      margin: 0;
      padding-top: 8px;
    }

    > a {
      background-color: $color_storm_dust_approx_light;
      cursor: pointer;
      display: block;
      font-weight: 700;
      padding: 6px 10px;
      position: relative;
      margin: 0 -10px;
      text-decoration: none;
      color: $white_dark;

      span {
        padding: 5px;
      }

      .item-name {
        display: block;
        width: auto;
        padding-right: 22px;
      }

      &:hover,
      &.active {
        background-color: $color_storm_dust_approx;
        color: $color_black_haze_approx;

        .icon-sm {
          color: $color_black_haze_approx;
        }

        .quiet {
          color: $white;
        }

        .sub-name {
          color: $white;
        }
      }

      .sub-name {
        clear: both;
        color: $white_dark;
        display: block;
        font-size: 9pt;
        font-weight: 400;
        line-height: 15px;
        margin-top: 4px;
      }
    }
  }
}

.btn:not(.btn-app) {
  margin: 0 2px 0 2px;

  &.btn-link {
    padding: 6px 8px;

    &:active,
    &:hover,
    &:focus {
      outline: none;
    }
  }
}

.btn-purple-style {
  background-color: transparent;
  border: none;

  .fas {
    color: #6b42a8;
  }

  .fas:hover {
    color: #401979;
    border: none;
  }
}

.btn-default {
  &:focus,
  &:hover {
    outline: 5px auto $btn-default-border;
  }
}

.btn-primary:not(.btn-app) {
  &:focus,
  &:hover,
  &.hover {
    outline: 5px auto $btn-primary-border !important;
    border-color: $btn-primary-border !important;
  }
}

.btn-primary.btn-app {
  &:focus,
  &:hover,
  &.hover {
    border-color: $btn-primary-border !important;
  }
}

.inputContainer {
  input {
    width: 100%;
    height: 30px;
  }
}

.react-autosuggest__suggestion {
  cursor: pointer;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}

.enter-Email-newTeamMember {
  input {
    width: 100%;
    height: 30px;
  }
}

.enter-name-newTeamMember {
  input {
    width: 100%;
    height: 30px;
  }
}

.enter-lastName-newTeamMember {
  input {
    width: 100%;
    height: 30px;
  }
}

.searchUserBtn {
  padding-left: 10px;
}

.login-box-body .form-control-feedback,
.register-box-body .form-control-feedback {
  top: 19px !important;
}

.control-link {
  cursor: pointer;
  padding-left: 10px;
  font-size: 18px;
  color: $color_black_haze_approx_dark;
}

.control-links {
  cursor: pointer;
  font-size: 18px;
  margin: 0;
  padding: 0;
  display: inline-flex;
}

.buttonInsightSummary {
  text-align: center;

  button {
    border: none;
  }
}

.possible {
  cursor: pointer;
}

.possible:hover {
  background-color: #f5f5f5;
}

.eraseQuestionButton {
  margin-top: 15px;
  border-radius: 3px;
  width: 50%;
  height: 100%;
}

.radioButtonsPositionCopyModal {
  padding: 0%;
}

.configInsightButton {
  float: right;
}

.btn-archive-answer {
  color: #b1d400;
  font-size: 14px;
  margin: 10px;
  cursor: pointer;
  float: right;
  outline: none;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .skin-strategyplace {
    .navbar-bread-crumbs {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .skin-strategyplace {
    .content {
      padding: 5px;
    }

    .nav-tabs-custom {
      margin-top: 70px;

      .tab-content {
        padding: 2px;
      }
    }

    .theme_container {
      padding: 2px;
    }

    .question_container {
      padding: 0;
    }

    .answer_container {
      padding: 1px;
    }

    .menu_mob_tools {
      padding-left: 12px;
      padding-right: 8px;
    }
  }
}

// Switch for the toolPropierties
.configName {
  padding-left: 15px;
  margin: 0;
  font-size: 1.5rem;
}

.theSwitch {
  padding-top: 20px;
}

input#backgroundCheckbox {
  display: none;
}

hr {
  margin-top: 0px;
  padding-bottom: 3px;
}

.switcherLabel {
  display: inline-block;
  position: relative;
  width: 90px;
  height: 35px;
  margin: 0;
}

.background {
  cursor: pointer;
  border-radius: 40px;
  position: absolute;
  width: 50px;
  height: 16px;
  z-index: 1;
  top: 5px;
  left: 5px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  background-color: #d0d0d0;
}

.state {
  padding-top: 3px;
  padding-left: 90px;
  transition-duration: 0.7s;
}

.background::before {
  position: absolute;
  top: -3px;
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #b8b8b8;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

input#backgroundCheckbox:checked + .background::before {
  background: #9ee466;
  transform: translateX(25px);
}

input#backgroundCheckbox:checked + .background {
  background-color: $color_black_haze_approx;
}

input#backgroundCheckbox:checked + .background .state {
  padding-left: 45px;
}

input#backgroundCheckbox:checked + .background .state::after {
  padding-left: 5px;
}

.addToTableButton {
  cursor: pointer;
}

.skin-strategyplace {
  .timeline {
    padding-bottom: 20px;

    > li {
      > .timeline-item {
        border: 1px solid #f4f4f4;
      }
    }

    > .time-label {
      > span {
        background-color: #9cbb00;
      }
    }
  }
}

/*
.form-group {
    margin-bottom: 0px !important;
}
*/

.customRow {
  @include clearfix;
  max-width: $grid-width;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: $gutter-vertical;
  }

  [class^="col-"] {
    float: left;

    &:not(:last-child) {
      margin-right: $gutter-horizontal;
    }
  }

  .col-1-of-2 {
    width: calc((100% - #{$gutter-horizontal}) / 2);
  }
}

#insight {
  .my-header-class {
    border-top: 3px solid #9cbb00;
    background-color: #f4ffba;
  }
}

.answer-counter {
  text-align: right;
}

.formFieldStyleOne {
  padding: 0;
  width: 100%;
}

.formFieldStyleTwo {
  padding: 0;
  width: 100%;
}

@media screen and (min-width: 900px) {
  .formFieldStyleOne {
    margin-left: 10px;
    padding: 0;
    width: 23.7%;
  }

  .formFieldStyleTwo {
    margin-left: 10px;
    padding: 0;
    width: 32%;
  }
}

.badge-insight-goal {
  height: 33px;
  border-radius: 5px;
  vertical-align: middle;
  padding-top: 1rem;
}

.form-control[disabled] {
  background-color: rgb(249, 249, 249);
  border-color: rgb(204, 204, 204);
}

/** 
 *  === PARTICIPANT === 
**/
.btn-link-fadeIn {
  position: relative;
  left: -11px;
  font-size: 1.4rem;
}

.my-bagdge {
  width: 65px !important;
  font-size: 8px;
  background-color: #0c5be9;
  position: relative;
  right: -20px;
  top: -11px;
}

.badgeMember {
  @extend .my-bagdge;
  background-color: #0c5be9;
}

.badgeParticipant {
  @extend .my-bagdge;
  background-color: #9ebc06;
}

.member-text,
.participant-text {
  .members,
  .participants {
    display: inline-block;
    width: 70px;
    height: 20px;
    font-size: 10px;
    line-height: 15px;
    text-align: center;

    i {
      position: relative;
      height: 16px;
      font-size: 8px;
    }
  }

  .members {
    color: #6b42a9;
    border: 1px solid #6b42a9;
  }

  .participants {
    color: #6eac16;
    border: 1px solid #6eac16;
    width: 90px;
  }

  .badge {
    margin: 0px;
    background-color: transparent;
    font-weight: normal;
  }
}

.selectFilterMemberParticipant {
  width: 209px;
  left: 110px;
  top: 33px;
  z-index: 5;
  position: relative;

  .fas {
    position: relative;
  }

  select {
    height: 33px;
  }
}

.btn-select {
  position: relative;
  background: #6b42a9;
  border-radius: 50%;
  border: none;
  width: 33px;
  height: 33px;
  font-size: 13px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);

  .fas {
    color: #fff;
    border-radius: 100px;
  }
}

.btn-select:hover {
  background-color: #401979;
  position: relative;
  border: none;
}

.evaluation-btns-grid {
  float: right;
  vertical-align: middle;
  top: -44px;
}

.label {
  font-weight: 500;
}